import { useEffect, useState } from "react";
//Router
import { useSearchParams } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
// ** MUI Imports
import Drawer from "@mui/material/Drawer";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Moduels
import NewDebt from "./NewDebt";
import ChangeDebt from "./ChangeDebt";

function DebtDrawer() {
  // Hooks Router
  const [searchParams, setSearchParams] = useSearchParams();
  const debtParam = searchParams.get("debt");
  const oid = searchParams.get("oid");

  // Handler
  const handleClose = () => {
    // console.log({searchParams})
    searchParams.delete("debt");
    setSearchParams(searchParams);
  };

  let isNew = debtParam == "new";

  // console.log({debtParam})

  return (
    <Drawer
      open={!!debtParam}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: false }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: 600 } } }}
    >
      <Box>
        <DrawerHeader
          title={isNew ? "Создать задолженность" : "Изменить задолженность"}
          handleClose={handleClose}
        />
        {isNew ? <NewDebt orderId={oid}  handleClose={handleClose}/> : <ChangeDebt debtId={debtParam}/>}
      </Box>
      {/* {hasData ? (
        <DrawerContent data={order} handleClose={handleClose} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )} */}
    </Drawer>
  );
}

function DrawerHeader({ title, handleClose }) {
  return (
    <Box
      sx={{
        py: 3,
        px: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <IconButton
        size="small"
        onClick={handleClose}
        sx={{ color: "text.primary" }}
      >
        <Icon icon="mdi:close" fontSize={20} />
      </IconButton>
    </Box>
  );
}

export default DebtDrawer;
