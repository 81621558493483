import { IconButton, Box } from "@mui/material";
// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import WalletService from "src/service/Wallet";

import { toast } from "react-hot-toast";

export default function Favorite({
  style = {},
  isFavorite = false,
  isKit = false,
  _id,
}) {
  let iconName = isFavorite ? "ph:star-fill" : "ph:star-light";

  const saveKitHandler = () => {
    WalletService.changeKit({
      _id: _id,
      isFavorite: !isFavorite,
    }).then(() => {
      toast.success(`Комплект изменен!`);
    });
  };

  const saveRequisiteHandler = () => {
    WalletService.changeRequisite({
      _id: _id,
      isFavorite: !isFavorite,
    }).then(() => {
      toast.success(`Реквизит изменен!`);
    });
  };

  const saveHandler = () => {
    if (isKit) {
      saveKitHandler();
    } else {
      saveRequisiteHandler();
    }
  };

  return (
    <Box
      sx={{
        ...style,
      }}
    >
      <IconButton
        onClick={saveHandler}
        sx={{
          color: isFavorite ? "primary.dark" : "secondary.light",
          borderRadius: 1,
        }}
      >
        <Icon icon={iconName} fontSize="24px" />
      </IconButton>
    </Box>
  );
}
