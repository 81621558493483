import { useState, useEffect } from "react";
// Mui
import {
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  TextField,
} from "@mui/material";

// Components
import FieldItem from "src/components/Currency/FieldItem";
import SimpleSelectCurrency from "src/components/Currency/SimpleSelectCurrency";

// utils
import formattedValue from "src/utils/currency/formattedValue";

// Services
import CurrenciesService from "src/service/Currencies";
import DebtService from "src/service/Debt";

// Form
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";

function NewDebt(props) {
  let { orderId, handleClose } = props;
  // States
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [currencies, setCurrencies] = useState([]);

  // console.log({ orderId }, "orderId");
  // Hooks
  useEffect(() => {
    setLoaded(false);
    DebtService.getPreCreateInfo(orderId).then((r) => {
      setData(r.data);
      setLoaded(true);
    });
    CurrenciesService.getList({}).then((r) => setCurrencies(r.data));
  }, []);

  if (!loaded || !currencies.length) return loadedComponent;

  return (
    <DebtContent
      orderId={orderId}
      handleClose={handleClose}
      currencies={currencies}
      data={data}
    />
  );
}

function DebtContent({ data, currencies, orderId, handleClose }) {
  // States
  const [currencyId, setCurrencyId] = useState(data.currency);
  const [isSubmiting, setIsSubmiting] = useState(false);

  // Calc
  let currency = currencies.find((c) => c._id === currencyId);
  let code = currency?.code;

  // Validation
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      sum: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    let body = {
      orderId,
      name: d.name,
      sum: d.sum,
      currencyId: currency._id,
      data: {
        email: data.email,
        ip: data.ip,
        fields: data.fields,
      },
    };
    setIsSubmiting(true);

    DebtService.create(body)
      .then((r) => {
        toast.success("Задолженность создана!");
        handleClose();
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  });

  return (
    <Box sx={{ px: 4, pt: 2 }}>
      <Box component="form" noValidate onSubmit={onSubmit}>
        <Controller
          name={"name"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              sx={{ mb: 3 }}
              fullWidth
              size="small"
              label="Название"
              placeholder="Введите название долга"
              id="name"
              type="text"
              error={Boolean(errors.name?.message)}
              {...register("name", {
                required: "Обязательное поле!",
              })}
              helperText={errors.name?.message}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <SimpleSelectCurrency
          label="Валюта"
          value={currencyId}
          size="small"
          onChange={setCurrencyId}
          currencies={currencies}
          containerStyle={{ mb: 3 }}
        />
        <Controller
          name={"sum"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              sx={{ mb: 3 }}
              fullWidth
              size="small"
              label={`Сумма ${code}`}
              placeholder="Введите сумму долга"
              id="sum"
              type="number"
              error={Boolean(errors.sum?.message)}
              {...register("sum", {
                required: "Обязательное поле!",
              })}
              helperText={errors.sum?.message}
              onChange={onChange}
              value={value}
            />
          )}
        />
        {/* валюта сумма */}
        <Card sx={{ bgcolor: (t) => t.palette.background.default }}>
          <Box sx={{ px: 3, pt: 2 }}>
            <FieldItem name="Email" value={data.email} showCopy />
            <FieldItem name="IP" value={data.ip} showCopy />
          </Box>

          <Divider />
          <Box sx={{ px: 3, pb: 2, maxHeight: "300px", overflowY: "scroll" }}>
            {data.fields.map((f) => {
              return (
                <FieldItem
                  name={f.name}
                  value={formattedValue(f.value, f.validation)}
                  showCopy
                />
              );
            })}
          </Box>
        </Card>
        <Button
          onClick={onSubmit}
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
          size="large"
          type="submit"
          variant="contained"
          sx={{ mt: 4 }}
          fullWidth
        >
          Добавить
        </Button>
      </Box>
    </Box>
  );
}

const loadedComponent = (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    }}
  >
    <CircularProgress />
  </Box>
);

export default NewDebt;
