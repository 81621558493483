import { useEffect, useState } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";

import WalletService from "src/service/Wallet";
import toast from "react-hot-toast";

export default function SpecialIssueKit({ walletId, isCrypto, isTurnRound }) {
  let [data, setData] = useState(null);


  // Calc
  let show = isCrypto && isTurnRound;

  useEffect(() => {
    WalletService.getIssueKitByWallet(walletId).then((res) => {
      setData(res.data);
    });
  }, [walletId]);

  const changeEnabledHandler = () => {
    let newEnabled = !data.enabled;
    WalletService.issueKitChange({ _id: data._id, enabled: newEnabled }).then(
      ({ data }) => {
        toast.success(`Режим Z-2 ${newEnabled ? "включен" : "выключен"}`);
        setData(data);
      }
    );
  };

  if (!show) return null;

  if (!data) return null;

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch checked={data.enabled} onChange={changeEnabledHandler} />
        }
        label="Режим Z-2"
      />
    </Box>
  );
}
