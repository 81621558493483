import { ListItemButton, Box, Typography } from "@mui/material";

// ** Icon Imports
import Icon from "src/@core/components/icon";

export default function KitItem(props) {
  let { clickHandler, kit, isOpen, code, sizeText, hasRequisites, currency, hideFavorite = false } =
    props;

  // Calc
  let { enabled, name, balance, isFavorite } = kit;

  let textColor = "inherit";

  if (!enabled) {
    textColor = "text.secondary";
  }

  return (
    <ListItemButton selected={isOpen} onClick={() => clickHandler(kit)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          py: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& svg": {
              color: "primary.dark",
            },
          }}
        >
          {isFavorite && !hideFavorite && <Icon icon="ph:star-fill" fontSize="18px" />}
          <Box sx={{ ml: 1 }}>
            <Typography noWrap={false} variant={sizeText} color={textColor}>
              {name}
            </Typography>
            {currency && (
              <Typography
                noWrap={false}
                sx={{ fontSize: 12, lineHeight: 1 }}
                color={textColor}
              >
                {currency.name}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Typography color={textColor} variant={sizeText}>
            {`${balance}${code ? " " + code : ""}`}
          </Typography>
          {hasRequisites && (
            <Icon icon={isOpen ? "mdi:chevron-up" : "mdi:chevron-down"} />
          )}
        </Box>
      </Box>
    </ListItemButton>
  );
}
