import { Button, CircularProgress, Grid } from "@mui/material";

export default function ActionBtns({
  isSubmitting,
  submitHandler,
  showBtn,
}) {

  return (
    <Grid sx={{ mt: 3 }} justifyContent="space-between" container spacing={2}>
      <Grid item xs={6}>
        {showBtn && (
          <Button
            fullWidth
            sx={{ fontSize: "0.8rem" }}
            variant="contained"
            color="primary"
            onClick={submitHandler}
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : null}
          >
            Подтвердить отправление
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
