// Mui
import { Box, Grid } from "@mui/material";

// Components
import SimpleSelectCurrency from "src/components/Currency/SimpleSelectCurrency";

function SelectPair({
  style = {},
  pairs = [],
  takeId,
  giveId,
  setTakeId,
  setGiveId,
}) {
  const takes = [
    ...new Map(
      pairs.map((p) => p.take).map((item) => [item._id, item])
    ).values(),
  ];

  const gives = takeId
    ? pairs.filter((p) => p.take._id == takeId).map((p) => p.give)
    : [];

  return (
    <>
      <Grid item  xs={6} md={3}>
        <SimpleSelectCurrency
          label="Отдаю"
          size="small"
          // containerStyle={{ mt: 2 }}
          currencies={takes}
          value={takeId}
          onChange={(v) => setTakeId(v)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <SimpleSelectCurrency
          label="Получаю"
          size="small"
          // containerStyle={{ mt: 2 }}
          currencies={gives}
          value={giveId}
          onChange={(v) => setGiveId(v)}
        />
      </Grid>
    </>
  );
}

export default SelectPair;
