// Mui
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FormControlLabel, Checkbox } from "@mui/material";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Toast
import toast from "react-hot-toast";

// Service
import CurrencyService from "src/service/Currencies";

// Form
import { useForm, Controller } from "react-hook-form";

function OtherBase(props) {
  let { isCrypto, info, isPaymentLink } = props;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      bccode: info.bccode || "",
      transLink: info.transLink || "",
      hideConfirmBtnWithPaymentLink:
        info.hideConfirmBtnWithPaymentLink || false,
    },
  });

  const onSubmit = handleSubmit((d) => {
    let fetchObj = {
      _id: info._id,
      data: {
        bccode: d.bccode,
        transLink: d.transLink,
        hideConfirmBtnWithPaymentLink: d.hideConfirmBtnWithPaymentLink,
      },
      action: "base",
    };
    CurrencyService.infoChange(fetchObj).then((r) => {
      toast.success("Настройки измененны!");
    });
  });

  return (
    <CardContent>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Настройки
      </Typography>
      <Grid container spacing={4}>
        {isCrypto && (
          <Grid item xs={12} sm={6}>
            <Controller
              name={"transLink"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Ссылка на транзакцию"
                  placeholder="https://blockchain.com/address/$address$/transactions (по символу замены $$, иначе в конец строки)"
                  id="transLink"
                  type="text"
                  error={Boolean(errors.transLink?.message)}
                  {...register("transLink", {})}
                  helperText={errors.transLink?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Controller
            name={"bccode"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Код на Bestchange"
                placeholder="BTC"
                id="bccode"
                type="text"
                error={Boolean(errors.bccode?.message)}
                {...register("bccode", {})}
                helperText={errors.bccode?.message}
                onChange={(e) => onChange(e.target.value.toUpperCase())}
                value={value}
              />
            )}
          />
        </Grid>
        {isPaymentLink && (
          <Grid item xs={12} sm={6}>
            <Controller
              name={"hideConfirmBtnWithPaymentLink"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Cкрыть подтверждение при оплата по ссылке"
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      name="controlled"
                    />
                  }
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
        <Button
          endIcon={<Icon icon="material-symbols:save-outline" />}
          variant="contained"
          onClick={onSubmit}
        >
          Сохранить
        </Button>
      </Box>
    </CardContent>
  );
}
export default OtherBase;
