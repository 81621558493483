import axios from "axios";

class Wallet {
  createKit(data) {
    return axios.post("/wallet/kit", data);
  }
  changeKit(data) {
    return axios.put("/wallet/kit", data);
  }
  getKits(params) {
    return axios.get("/wallet/kit", { params });
  }
  deleteKit(id) {
    return axios.delete("/wallet/kit", { data: { id } });
  }
  sortKits(data) {
    return axios.post("/wallet/kit/sort", data);
  }

  createRequisite(data) {
    return axios.post("/wallet/kit/requisite", data);
  }

  getRequisiteList(kitId) {
    return axios.get("/wallet/kit/requisite", { params: { kitId } });
  }
  changeRequisite(data) {
    return axios.put("/wallet/kit/requisite", data);
  }
  deleteRequisite(id) {
    return axios.delete("/wallet/kit/requisite", { data: { id } });
  }
  sortRequisites(data) {
    return axios.post("/wallet/kit/requisite/sort", data);
  }
  // Common
  changeBalance(data) {
    return axios.put("/wallet/balance", data);
  }
  getBalance(id) {
    return axios.get("/wallet/balances", { params: { id } });
  }
  // balances operations
  balanceTransaction(data) {
    return axios.post("/wallet/balance/transaction", data);
  }
  balanceTransfer(data) {
    return axios.post("/wallet/balance/transfer", data);
  }
  // Currency wallet
  changeWalletCurrency(data) {
    return axios.put("/wallet/currency", data);
  }
  // order
  getIncomeKitsList(params) {
    return axios.get("/wallet/order/income-kits", { params });
  }
  getOutcomeKitsList(params) {
    return axios.get("/wallet/order/outcome-kits", { params });
  }

  // General
  getGeneralKits() {
    return axios.get("/wallet/general");
  }
  activateGeneralKit(data) {
    return axios.put("/wallet/general", data);
  }
  // Directions
  changeDirectionHandler(data) {
    return axios.put("/wallet/directions/currencies", data);
  }
  // issue
  getIssueKitByWallet(id) {
    return axios.get("/wallet/issue-kit", {
      params: {
        id,
      },
    });
  }
  issueKitChange(data) {
    return axios.put("/wallet/issue-kit", data);
  }
  ///

  requisiteChangeConfirm(data) {
    return axios.put("/wallet/requisite-change-confirm", data);
  }

  // General address
  // /wallet/general-address
  generalAddressGetAll() {
    return axios.get("/wallet/general-address");
  }
  generalAddressCreate(data) {
    return axios.post("/wallet/general-address", data);
  }
  generalAddressDelete(id) {
    return axios.delete("/wallet/general-address", {
      params: {
        id,
      },
    });
  }
  generalAddressChange(upd) {
    return axios.put("/wallet/general-address", upd);
  }
  //
}

export default new Wallet();
