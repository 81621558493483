import { makeObservable, observable, action, computed } from "mobx";

class CryptoCurrencyNets {
  nets = [];
  constructor() {
    makeObservable(this, {
      nets: observable,
      setNetsList: action,
      addItem: action,
      getNets: computed,
    });
  }
  get getNets() {
    return this.nets;
  }
  setNetsList(list) {
    this.nets = list;
  }
  addItem(item) {
    this.nets = [item, ...this.nets];
  }
  //   get getSettlementСurrency(){
  //     return this.settlementСurrency
  //   }
}

const cryptoCurrencyNetsStore = new CryptoCurrencyNets();

export default cryptoCurrencyNetsStore;
