import { useState, useEffect, Fragment } from "react";
//Mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTheme, useMediaQuery } from "@mui/material";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import WalletService from "src/service/Wallet";

// Toast
import { toast } from "react-hot-toast";

// Draggable
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function RequisitesWrap(props) {
  let {
    addHandler,
    RequisiteComponent,
    RequisiteComponentProps = {},
    requisites = [],
    isCrypto,
    currency,
  } = props;
  // States
  let [isSorting, setIsSorting] = useState(false);
  let [sortRequisites, setSortRequisites] = useState([]);

  // Calcs
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // Handlers
  const saveSortHandler = () => {
    let requisitesList = sortRequisites.map((r, i) => {
      return Object.assign({}, r, { srt: i + 1 });
    });
    WalletService.sortRequisites({
      sort: requisitesList.map((c) => ({ _id: c._id, srt: c.srt })),
    }).then(() => {
      toast.success("Реквизиты отсортированны!");
      setIsSorting(false);
    });
  };

  // Drag and drop

  const startSortingHandler = () => {
    setSortRequisites(requisites);
    setIsSorting(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const list = reorder(
      sortRequisites,
      result.source.index,
      result.destination.index
    );

    setSortRequisites(list);
  };

  let requisiteListComponent = requisites.map((r) => {
    return (
      <RequisiteComponent
        key={r._id}
        data={r}
        currency={currency}
        style={{ mb: 2 }}
        {...RequisiteComponentProps}
      />
    );
  });

  if (isSorting) {
    requisiteListComponent = (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <Box
              sx={{ mt: 6 }}
              container
              spacing={4}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sortRequisites.map((r, index) => (
                <Draggable key={r._id} draggableId={r._id} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      key={r._id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <RequisiteComponent
                        key={r._id}
                        data={r}
                        currency={currency}
                        {...RequisiteComponentProps}
                        style={{ mb: 2 }}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return (
    <Box>
      <Grid sx={{ mb: 3 }} justifyContent="space-between" container spacing={4}>
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            onClick={() => addHandler()}
            fullWidth
            startIcon={<Icon icon="material-symbols:add" />}
          >
            Добавить
          </Button>
        </Grid>
        {isCrypto && (
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              onClick={() => addHandler(true)}
              fullWidth
              startIcon={<Icon icon="material-symbols:add" />}
            >
              общий
            </Button>
          </Grid>
        )}

        <Grid item xs={6} md={6}>
          {isDesktop && (
            <Grid container justifyContent="flex-end" spacing={4}>
              {isSorting ? (
                <Fragment>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="medium"
                      onClick={saveSortHandler}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      sx={{ ml: 4 }}
                      fullWidth
                      variant="outlined"
                      size="medium"
                      onClick={() => setIsSorting(false)}
                    >
                      Отменить
                    </Button>
                  </Grid>
                </Fragment>
              ) : (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    startIcon={<Icon icon="mdi:sort-numeric-descending" />}
                    variant="outlined"
                    size="medium"
                    onClick={startSortingHandler}
                  >
                    Сортировать
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box>{requisiteListComponent}</Box>
      <Grid sx={{ mb: 3 }} justifyContent="space-between" container spacing={4}>
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            onClick={() => addHandler()}
            fullWidth
            startIcon={<Icon icon="material-symbols:add" />}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RequisitesWrap;
