import { useCountdown } from "src/hooks/countDownSeconds";

//
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// time() {

// },

function Timer(props) {
  const {
    finished,
    TypographyProps,
    style = {},
    iconName,
    iconColor = "primary",
    iconFontSize = '20px'
  } = props;
  const [seconds] = useCountdown(finished);

  //   console.log({ seconds });
  let textTime = getTextTime(seconds);

  if (!textTime) return null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& svg": {
          color: (t) => t.palette[iconColor].light,
        },
        ...style,
      }}
    >
      {iconName && <Icon fontSize={iconFontSize} style={{marginRight: '8px'}} icon={iconName} />}
      <Typography  {...TypographyProps}>{textTime}</Typography>
    </Box>
  );
  // console.log({seconds})
}

function getTextTime(seconds) {
  if (seconds <= 0) return null;

  let min = Math.floor(seconds / 60);
  let sec = Math.floor(seconds % 60);

  return `${min} мин. ${sec} сек.`;

  //     let diff = new Date(this.order.finished) - this.now;
  //
  //     let sec = Math.floor((diff % 60000) / 1000);
  //     if (diff <= 0) {
  //         return "";
  //     } else if
  //     return `${min} мин. ${sec} сек.`;
}

// (this.order.status > 103) {
//         return "";
//     }

export default Timer;
