import { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";

function Filter(props) {
  let { site, setSite, status, setStatus, WEBSITES, STATUSES } = props;

  return (
    <Box>
      <TabContext value={site}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, v) => setSite(v)}
        >
          {WEBSITES.map((w) => (
            <Tab key={w.value} value={w.value} label={w.name} />
          ))}
        </TabList>
      </TabContext>
      <TabContext value={status}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, v) => setStatus(v)}
        >
          {STATUSES.map((w) => (
            <Tab key={w.value} value={w.value} label={w.name} />
          ))}
        </TabList>
      </TabContext>
    </Box>
  );
}

export default Filter;
