import { useEffect, useState } from "react";

// Mui
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/Table";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { CardContent, TextField } from "@mui/material";

// Toast
import { toast } from "react-hot-toast";

// Service
import ServiceOrder from "src/service/Order";

function OutcomeTable(props) {
  let { outcomeInfo, isChanged = false, handleClose, orderId } = props;

  // States
  let [list, setList] = useState(outcomeInfo.list);
  let [isSubmit, setSubmit] = useState(false);

  // Calc
  let isKit = outcomeInfo.isKit;
  let existIsKitInList = outcomeInfo.list.some((i) =>
    i.hasOwnProperty("isKit")
  );
  let existIsReqInList = outcomeInfo.list.some((i) => !i.isKit);
  let showForReq = !isKit;

  if (existIsKitInList) {
    showForReq = existIsReqInList;
  }

  let updatedAt = outcomeInfo.updatedAt;
  let showList = isChanged ? list : outcomeInfo.list;

  // Hooks
  useEffect(() => {
    setList(outcomeInfo.list);
  }, [updatedAt]);

  const changeItemHandler = (id, key, value) => {
    setList((list) =>
      list.map((i) =>
        i._id == id ? Object.assign({}, i, { [key]: value }) : i
      )
    );
  };

  const saveCommissionHandler = () => {
    setSubmit(true);
    ServiceOrder.changeCommission(orderId, { list })
      .then((r) => {
        toast.success("Комиссия измененна!");
        handleClose();
      })
      .finally(() => setSubmit(false));
  };

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Комплект</TableCell>
              {showForReq && <TableCell>Реквизит</TableCell>}

              <TableCell>Сумма</TableCell>
              <TableCell>Комиссия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showList.map((row) => (
              <TableRow
                key={row._id}
                sx={{
                  "&:last-of-type td, &:last-of-type th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.kitName}
                </TableCell>
                {showForReq && (
                  <TableCell align="left">{row.requisiteName}</TableCell>
                )}

                <TableCell align="left">
                  {!isChanged ? (
                    row.sum
                  ) : (
                    <TextField
                      value={row.sum}
                      onChange={(e) =>
                        changeItemHandler(row._id, "sum", e.target.value)
                      }
                      size="small"
                      type="number"
                    />
                  )}
                </TableCell>
                <TableCell align="left">
                  {!isChanged ? (
                    row.cms
                  ) : (
                    <TextField
                      value={row.cms}
                      onChange={(e) =>
                        changeItemHandler(row._id, "cms", e.target.value)
                      }
                      size="small"
                      type="number"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ my: "0px !important" }} />
      {isChanged && (
        <CardContent sx={{ p: 3 }}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{ fontSize: "0.8rem" }}
                variant="contained"
                color="primary"
                onClick={saveCommissionHandler}
                disabled={isSubmit}
                startIcon={isSubmit ? <CircularProgress /> : null}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{ fontSize: "0.8rem" }}
                variant="outlined"
                color="error"
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </>
  );
}

export default OutcomeTable;
