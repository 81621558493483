import { useEffect, useState } from "react";
// Mui
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import { Typography } from "@mui/material";

// Constants
import WEBSITESANDSERVICES from "src/configs/websitesAndServices";

// Service
import SiteShiftPriceService from "src/service/SiteShiftPrice";
import { toast } from "react-hot-toast";

function CryptoPriceShift() {
  let [list, setList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Hooks
  useEffect(() => {
    SiteShiftPriceService.getAll().then((r) => setList(r.data));
  }, []);

  // Handlers
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const updateItemHandler = (upd) => {
    setList((list) => list.map((i) => (i._id == upd._id ? upd : i)));
  };

  let selectedData = selectedIndex && list.find((s) => s.sc == selectedIndex);

  return (
    <Card>
      <CardHeader
        title={"Сдвиг курсов на крипту"}
        subheader="Введите процент до 0,1%, тем самым цена каждой валюты будт отличаться в +- 0,1% от оригинальной."
      />
      <CardContent>
        <List>
          {list.map((site) => {
            return (
              <ListItemButton
                selected={selectedIndex === site.sc}
                onClick={() => handleListItemClick(site.sc)}
              >
                <ListItemText
                  primary={WEBSITESANDSERVICES.find((s) => s.id == site.sc)?.name}
                />
                <Typography variant="body2">{site.percent}%</Typography>
              </ListItemButton>
            );
          })}
        </List>
      </CardContent>
      {selectedData && (
        <ChangePriceSection
          updateItemHandler={updateItemHandler}
          data={selectedData}
          name={WEBSITESANDSERVICES.find((s) => s.id == selectedData.sc)?.name}
        />
      )}
    </Card>
  );
}

function ChangePriceSection({ name, data, updateItemHandler }) {
  // States
  let [percent, setPercent] = useState(data.percent);
  let [isSubmiting, setSubmiting] = useState(false);

  // Hooks
  useEffect(() => {
    setPercent(data.percent);
  }, [data._id]);

  const saveHandler = () => {
    setSubmiting(true);
    SiteShiftPriceService.change(data._id, {
      percent: percent,
    })
      .then((r) => {
        updateItemHandler(r.data);
        toast.success("Сдвиг изменен");
      })
      .finally(() => {
        setSubmiting(false);
      });
  };

  // Calc
  let isChanged = +percent != data.percent && +percent >= 0 && +percent <= 0.1;

  return (
    <CardContent>
      <Typography sx={{ mb: 4 }} variant="h6">
        {name}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            placeholder="Макс. 0.1%"
            value={percent}
            onChange={(e) => {
              setPercent(e.target.value);
            }}
            size="small"
            label="Процент"
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={!isChanged || isSubmiting}
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : <Icon icon="material-symbols:save-outline-rounded" />}
            variant="contained"
            onClick={saveHandler}
            fullWidth
          >
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default CryptoPriceShift;
