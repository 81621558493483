import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  CardContent,
  DialogTitle,
  Popover,
  CircularProgress,
  IconButton,
  Box,
  Avatar,
  Button,
  List,
  ListItemButton,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";

// Services
import CurrenciesService from "src/service/Currencies";
import WalletService from "src/service/Wallet";

import { Icon } from "@iconify/react";

// Config
import ConfigApi from "src/configs/api";

// Components
import SimpleSelectCurrency from "src/components/Currency/SimpleSelectCurrency";
import toast from "react-hot-toast";

function ChangeListDalog({
  open,
  handleClose,
  originalCurrencyId = "",
  kitId,
  directions
}) {
  let [currencies, setCurrencies] = React.useState([]);
  let [loadedCurrencies, setLoadedCurrencies] = React.useState(false);

  // console.log(directions, 'directions')

  React.useEffect(() => {
    CurrenciesService.getList({}).then(({ data }) => {
      setCurrencies(data);
      setLoadedCurrencies(true);
    });
  }, []);
  //

  const closeHandler = () => {
    handleClose();
  };

  let loaderComponent = (
    <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
      <CircularProgress />
    </Box>
  );

  let view = loadedCurrencies ? (
    <ContentView
      kitId={kitId}
      handleClose={handleClose}
      currencies={currencies}
      initDirections={directions}
    />
  ) : (
    loaderComponent
  );

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width: {
            xs: "94%",
            md: "450px",
          },
        },
      }}
      open={open}
      onClose={closeHandler}
    >
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 5 }}>
          <Typography variant="h6">Направления выдачи</Typography>
          <IconButton onClick={handleClose} sx={{ p: 0.5 }}>
            <Icon icon="mingcute:close-line" />
          </IconButton>
        </Box>
        {view}
      </DialogContent>
    </Dialog>
  );
}

function ContentView({ currencies, handleClose, kitId, initDirections }) {
  let [directions, setDirections] = React.useState(initDirections.map(d => d._id));
  let [currencyId, setCurrencyId] = React.useState(currencies[0]._id);
  let [anchorEl, setAnchorEl] = React.useState(null);
  let [isSubmit, setIsSubmit] = React.useState(false);

  // Handlers
  const addDirection = (added) => {
    setDirections((prev) => addUniqueElements(prev, added));
  };

  const deleteDirection = (id) => {
    setDirections((prev) => prev.filter((d) => d !== id));
  };

  // Anchor
  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const saveDirectionsHandler = () => {
    setIsSubmit(true);
    WalletService.changeDirectionHandler({
      directions,
      kitId: kitId,
    })
    .then(()=>{
      toast.success('Направления выдачи сохраненны!')
      handleClose();
    })
    .finally(() => {
      setIsSubmit(false);
    });
  };

  // Calc
  let fullObjDirections = directions.map((did) => {
    let c = currencies.find((c) => c._id == did);
    return c;
  });

  return (
    <Box>
      <Button
        fullWidth
        size="small"
        onClick={handleClickAnchor}
        startIcon={<Icon icon="basil:add-outline" />}
        variant="contained"
      >
        Добавить
      </Button>
      <SelectCurrenciesPopover
        currencies={currencies}
        open={!!anchorEl}
        anchorEl={anchorEl}
        handleClose={handleCloseAnchor}
        addDirection={addDirection}
      />
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          overflowY: "auto",
          maxHeight: "70vh",
        }}
      >
        {fullObjDirections.map((cur) => {
          const id = cur._id;

          return (
            <ListItem
              secondaryAction={
                <IconButton
                  onClick={() => {
                    deleteDirection(id);
                  }}
                  sx={{ p: 1 }}
                  color="error"
                >
                  <Icon icon="mdi:trash-outline" />
                </IconButton>
              }
              key={id}
              //   disablePadding
            >
              <ListItemIcon>
                <Avatar
                  variant="square"
                  src={ConfigApi.PUBLIC_IMAGES + cur.image + ".png"}
                  alt={cur.name}
                  sx={{ height: 23, width: 23, mr: 3 }}
                />
              </ListItemIcon>
              <ListItemText primary={cur.name} />
            </ListItem>
          );
        })}
      </List>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          startIcon={isSubmit ? <CircularProgress /> : null}
          onClick={saveDirectionsHandler}
          variant="contained"
        >
          Сохранить
        </Button>
        <Button onClick={handleClose} variant="outlined" color="error">
          Отменить
        </Button>
      </DialogActions>
    </Box>
  );
}

function SelectCurrenciesPopover({
  open,
  anchorEl,
  handleClose,
  currencies = [],
  addDirection,
}) {
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const addHandler = () => {
    addDirection(checked);
    handleClose();
  };

  React.useEffect(() => {
    if (!open) {
      setChecked([]);
    }
  }, [open]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      PaperProps={{
        sx: {
          maxWidth: "450px",
          minWidth: "400px",
        },
      }}
    >
      <Box
        sx={{
          py: 2,
          px: 3,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleClose} sx={{ p: 1 }}>
            <Icon icon="mingcute:close-line" />
          </IconButton>
        </Box>
        <Box sx={{ py: 3 }}>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              overflowY: "auto",
              maxHeight: "70vh",
            }}
          >
            {currencies.map((cur) => {
              const id = cur._id;

              return (
                <ListItem
                  secondaryAction={
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  }
                  key={id}
                  disablePadding
                >
                  <ListItemButton onClick={handleToggle(id)} dense>
                    <ListItemIcon>
                      <Avatar
                        variant="square"
                        src={ConfigApi.PUBLIC_IMAGES + cur.image + ".png"}
                        alt={cur.name}
                        sx={{ height: 23, width: 23, mr: 3 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={cur.name} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Button
          sx={{ mt: 2 }}
          fullWidth
          size="small"
          onClick={addHandler}
          variant="contained"
        >
          Добавить
        </Button>
      </Box>
    </Popover>
  );
}

export default ChangeListDalog;

function addUniqueElements(arr, elements) {
  elements.forEach(function (element) {
    if (!arr.includes(element)) {
      arr.push(element);
    }
  });
  return arr;
}
