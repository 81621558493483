import { observer } from "mobx-react-lite";

// Mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";


// Store
import ServiceDataStore from "src/store/ServiceData";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Custom Components Imports
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Utils
import moment from "moment";
import "moment/locale/ru";

function FiatPrices() {
  let pricesData = ServiceDataStore.getFiatPrices;

  const prices = [
    {
      name: "USD",
      price: pricesData["USD"],
      icon: <Icon icon="uil:usd-circle" />,
      iconColor: "primary",
    },
    {
      name: "EUR",
      price: pricesData["EUR"],
      icon: <Icon icon="uil:euro-circle" />,
      iconColor: "info",
    },
    {
      name: "KZT",
      price: pricesData["KZT"],
      icon: <Icon icon="mdi:currency-kzt" />,
      iconColor: "info",
    },
    {
      name: "TRY",
      price: pricesData["TRY"],
      icon: <Icon icon="mdi:currency-try" />,
      iconColor: "primary",
    },
    {
      name: "UAH",
      price: pricesData["UAH"],
      icon: <Icon icon="mdi:currency-uah" />,
      iconColor: "primary",
    },
    {
      name: "BYN",
      price: pricesData["BYN"],
      icon: <Icon icon="gravity-ui:circle-ruble" />,
      iconColor: "primary",
    },
  ];

  let updated = moment(pricesData.updatedAt).format("DD MMMM YYYY, в HH:mm");

  return (
    <Card>
      <CardHeader
        sx={{ pb: 3.25 }}
        title="Фиат"
        titleTypographyProps={{ variant: "h6" }}
        action={
          <Typography variant="caption" sx={{ ml: 1.5 }}>
            {updated}
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={6}>
          {prices.map((p, i) => {
            return (
              <Grid item xs={6}>
                <CurrencyPrice {...p} index={i} />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
}

function CurrencyPrice({ index, price, name, icon, iconColor }) {
  return (
    <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        color={iconColor}
        sx={{ mr: 4 }}
      >
        {icon}
      </CustomAvatar>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {price} RUB
        </Typography>
        <Typography variant="caption">{name}</Typography>
      </Box>
    </Box>
  );
}

export default observer(FiatPrices);


