import { useState, useEffect } from "react";
import { Box, Typography, Button, Grid, CircularProgress } from "@mui/material";

import WalletService from "src/service/Wallet";

import { Icon } from "@iconify/react";
import toast from "react-hot-toast";

import { useCountdown } from "src/hooks/countDownSeconds";

// Socket
import { socket } from "src/socket";

export default function ConfirmChange({ addressId }) {
  let [data, setData] = useState(null);
  let [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    WalletService.requisiteChangeConfirm({
      action: "get-net",
      id: addressId,
    }).then(({ data }) => {
      if (data) {
        setData(data);
      }
    });
    socket.on("update_confirm_change_req", (data) => {
      if (data.netAddress == addressId) {
        setData(data);
      }
    });
    return () => {
      socket.off("update_confirm_change_req");
    };
  }, []);

  let [seconds] = useCountdown(data?.expire || Date.now());

  const cancelHandler = () => {
    setIsSubmitting(true);
    WalletService.requisiteChangeConfirm({
      action: "cancel-net",
      id: addressId,
    })
      .then(({ data }) => {
        if (data) {
          setData(data);
          toast.success("Изменение отменено!");
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  if (!data || (data && data.status !== "wait")) {
    return null;
  }
  // Calc
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        minHeight: "100%",
        bgcolor: "white",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          p: "12px 16px",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontWeight="bold" variant="body1">
            {seconds < 0 ? "0" : seconds} cек. для подтверждения
          </Typography>
          <Button
            onClick={cancelHandler}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
            startIcon={
              isSubmitting ? (
                <CircularProgress />
              ) : (
                <Icon icon="material-symbols:cancel-outline" />
              )
            }
            variant="outlined"
            color="error"
          >
            Отменить
          </Button>
        </Box>
        <Typography fontWeight="bold" variant="body1">
          Новые значения
        </Typography>
        <Box sx={{ width: "100%", height: "min-content", overflow: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldItem name="Название" value={data.name} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldItem name="Адрес кошелька" value={data.address} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

function FieldItem({ name, value }) {
  return (
    <Box>
      <Typography variant="body2">{name}:</Typography>
      <Typography
        sx={{
          wordBreak: "break-all",
        }}
        variant="body1"
      >
        {value}
      </Typography>
    </Box>
  );
}
