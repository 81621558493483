// MUi
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const options = [
  {
    name: "Все",
    value: "all",
  },
  {
    name: "Пара",
    value: "pair",
  },
];

function SelectFilter({ statusFilter, setStatusFilter }) {
  return (
    <Box>
      <FormControl size="small" fullWidth>
        <InputLabel id="select-status-filter">Фильтр</InputLabel>
        <Select
          labelId="select-status-filter"
          value={statusFilter}
          label="Фильтр"
          size="small"
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          {options.map((o) => (
            <MenuItem key={o.value} value={o.value}>
              {o.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectFilter;
