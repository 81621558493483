import { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";

// Config
import ConfigApi from "src/configs/api";
import sitesAndServices from "src/configs/websitesAndServices";

// ** Icon
import Icon from "src/@core/components/icon";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

//Toast
import { toast } from "react-hot-toast";

// Services
import ExchangeService from "src/service/Exchange";

// Socket
import { socket } from "src/socket";

// Time
import moment from "moment";
import { IconButton } from "@mui/material";

function CourseCard(props) {
  let { style, showTarget, data, deleteItem } = props;
  let [exchange, setExchange] = useState(data);
  let [mn, setMn] = useState(data.limit.mn);
  let [mx, setMx] = useState(data.limit.mx);
  let [prc, setPrc] = useState(data.rate.prc);
  let [mnUSD, setMnUSD] = useState(data.limit.mnUSD);
  let [mxUSD, setMxUSD] = useState(data.limit.mxUSD);

  let takeMainCur = exchange.tk.cur;
  let giveMainCur = exchange.gv.cur;

  // Hooks
  useEffect(() => {
    socket.on(`exchange_upd_${exchange._id}`, (e) => {
      setExchange(e);
      setMn(e.limit.mn);
      setMx(e.limit.mx);
      setMnUSD(e.limit.mnUSD);
      setMxUSD(e.limit.mxUSD);
    });
    socket.on(`exchange_upd_part_${exchange._id}`, (upd) => {
      setExchange((e) => {
        return Object.assign({}, e, upd);
      });
    });
    //
    return () => {
      socket.off(`exchange_upd_part_${exchange._id}`);
      socket.off(`exchange_upd_${exchange._id}`);
    };
  }, []);

  // Handlers
  const changeExchangeHandler = (upd) => {
    ExchangeService.changeExchange({
      _id: exchange._id,
      ...upd,
    }).then(() => {
      toast.success("Обменная пара изменена!");
    });
  };

  //Calc
  let isbn = exchange.rate.isbn;
  let isChanged =
    +exchange.limit.mn !== +mn ||
    +exchange.limit.mx !== +mx ||
    +exchange.rate.prc !== +prc ||
    (isNumeric(mnUSD) && +exchange.limit.mnUSD !== +mnUSD) ||
    (isNumeric(mxUSD) && +exchange.limit.mxUSD !== +mxUSD);

  return (
    <Card sx={{ ...style }}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CoursePartInfo
                  name={takeMainCur.name}
                  image={takeMainCur.image}
                  price={pairGetPrices(exchange).left}
                />
                <Box sx={{ mx: 3 }}>
                  <Icon icon="uil:exchange" />
                </Box>
                <CoursePartInfo
                  name={giveMainCur.name}
                  image={giveMainCur.image}
                  price={pairGetPrices(exchange).right}
                />
              </Box>
              {/* <Typography color="secondary" variant="caption">
                {moment(exchange.updatedAt).format("HH:mm:ss, DD.MM")}
              </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                sx={{
                  transform: `rotate(${exchange.rate.isbn ? 0 : 180}deg)`,
                  borderRadius: 0,
                  p: 0.5,
                  margin: "0px auto",
                  mr: 2,
                }}
                onClick={() =>
                  changeExchangeHandler({
                    rate: {
                      isbn: !isbn,
                    },
                  })
                }
                color={exchange.rate.isbn ? "success" : "error"}
              >
                <Icon icon="mdi:arrow-top-bold-box-outline" />
              </IconButton>
              <Grid
                sx={{
                  flexGrow: 1,
                }}
                container
                columns={16}
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={8} md={3}>
                  <TextField
                    size="small"
                    fullWidth
                    value={prc}
                    label="Процент"
                    type="number"
                    onChange={(e) => setPrc(e.target.value)}
                  />
                </Grid>
                <Grid item xs={8} md={3}>
                  <TextField
                    size="small"
                    fullWidth
                    value={mnUSD}
                    type="number"
                    label={`Мин. USD`}
                    onChange={(e) => setMnUSD(e.target.value)}
                  />
                </Grid>
                <Grid item xs={8} md={5}>
                  <TextField
                    size="small"
                    fullWidth
                    value={mn}
                    type="number"
                    label={`Мин. ${takeMainCur.code}`}
                    onChange={(e) => setMn(e.target.value)}
                  />
                </Grid>
                <Grid item xs={8} md={5}>
                  <TextField
                    size="small"
                    fullWidth
                    value={mx}
                    type="number"
                    label={`Макс. ${takeMainCur.code}`}
                    onChange={(e) => setMx(e.target.value)}
                  />
                </Grid>
              </Grid>
              {isChanged && (
                <IconButton
                  onClick={() => {
                    changeExchangeHandler({
                      limit: {
                        mn: Math.abs(+mn),
                        mx: Math.abs(+mx),
                        mnUSD: Math.abs(+mnUSD || 0),
                        mxUSD: Math.abs(+mxUSD || 0),
                      },
                      rate: {
                        prc: Math.abs(+prc),
                      },
                    });
                  }}
                  sx={{
                    margin: "0px auto",
                    borderRadius: 0,
                    p: 0.5,
                    ml: 2,
                  }}
                  color="primary"
                >
                  <Icon icon="mingcute:save-line" />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ display: "block" }}
            color="secondary"
            variant="caption"
          >
            <b>{sitesAndServices.find((s) => s.id == data.sc)?.name}</b>
          </Typography>
          <Typography
            sx={{
              display: "block",
              mt: {
                xs: 0,
                md: 2,
              },
            }}
            color="secondary"
            variant="caption"
          >
            {moment(exchange.updatedAt).format("HH:mm:ss, DD.MM")}{" "}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

function CoursePartInfo({ name, price, image }) {
  return (
    <Box sx={{ display: "flex" }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 40, height: 40, mr: 2 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image + ".png"}
          alt={name}
          sx={{ height: 30, width: 30 }}
        />
      </CustomAvatar>
      <Box>
        <Typography variant="body1">{name}</Typography>
        <Typography variant="body2" color="secondary">
          {price}
        </Typography>
      </Box>
    </Box>
  );
}

function pairGetPrices(exc) {
  const srv = exc.rate.srv;
  const ins = exc.rate.in;
  const rvs = exc.rate.rvs;

  return {
    left: ins,
    right: srv,
  };
}

function isNumeric(value) {
  return !isNaN(value) && !isNaN(parseFloat(value));
}

export default CourseCard;
