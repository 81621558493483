import { useState, useEffect } from "react";

import {
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  Button,
} from "@mui/material";

// Custom Components
import ViewImage from "src/components/ViewImage";

import CustomTextField from "../modules/Ui/CustomTextField";
import useBgColor from "src/@core/hooks/useBgColor";

import CryptocurrencyNetsStore from "../../../store/currency/CryptocurrencyNets";
import { observer } from "mobx-react-lite";
import ConfigApi from "src/configs/api";

import Icon from "src/@core/components/icon";
import WalletService from "src/service/Wallet";
import toast from "react-hot-toast";

import ConfirmChange from "./ConfirmChange";

// Socket
import { socket } from "src/socket";

function AddressItem({ data = {}, style = {}, deleteItem }) {
  const [aData, setAData] = useState(data);
  const [name, setName] = useState(data.name);
  const [address, setAddress] = useState(data.address);
  const [showQr, setShowQr] = useState(false);

  useEffect(() => {
    socket.on("general_address_upd", (upd) => {
      if (upd._id == aData._id) {
        setAData(data);
        if (upd.name) {
          setName(upd.name.trim());
        }
        if (upd.address) {
          setAddress(upd.address.trim());
        }
      }
    });
    return () => {
      socket.off("general_address_upd");
    };
  }, []);

  let isChanged =
    name.trim() !== aData?.name?.trim() ||
    address.trim() !== aData?.address?.trim();

  const deleteHandler = () => {
    if (
      !window.confirm(
        "Вы уверены? Все связаные реквизиты в комплектах будут удалены!"
      )
    ) {
      return;
    }
    WalletService.generalAddressDelete(data._id).then(() => {
      deleteItem(data._id);
      toast.success("Адрес удален!");
    });
  };

  const saveHandler = () => {
    WalletService.generalAddressChange({
      _id: data._id,
      name,
      address,
    }).then(() => {
      toast.success(`Ожидает подтверждения!`);
    });
  };

  return (
    <Card sx={{ ...style, position: "relative" }}>
      <ConfirmChange addressId={data._id} />
      <ViewImage
        show={showQr}
        handleClose={() => setShowQr(false)}
        isQrCode
        value={address}
      />
      <CardContent
        sx={{
          p: "14px 18px !important",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Название"
              placeholder="Введите название"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Адрес кошелька"
              placeholder="Введите адрес кошелька"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <NetInfo netId={data.net} />
          </Grid>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                // minHeight: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {isChanged && (
                  <Button
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      minWidth: 38,
                      mb: {
                        xs: 0,
                        md: 2,
                      },
                      alignSelf: "flex-start",
                    }}
                    color="primary"
                    onClick={saveHandler}
                  >
                    <Icon icon="material-symbols:save-outline-rounded" />
                  </Button>
                )}

                {address && (
                  <Button
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      minWidth: 38,
                      mb: {
                        xs: 0,
                        md: 3,
                      },
                      ml: 3,
                    }}
                    color={"secondary"}
                    onClick={() => setShowQr(true)}
                  >
                    <Icon icon="material-symbols:qr-code-2-rounded" />
                  </Button>
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  sx={{
                    p: 1.5,
                    minWidth: 38,
                    height: "max-content",
                    // ml: {
                    //   xs: 0,
                    //   md: 3,
                    // },
                  }}
                  color={"error"}
                  onClick={() => deleteHandler()}
                >
                  <Icon icon="ic:round-delete-forever" />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function NetInfo({ netId }) {
  const bgColors = useBgColor();
  const nets = CryptocurrencyNetsStore.getNets;
  const net = nets.find((n) => n._id == netId);

  if (!net) return null;

  return (
    <Box
      sx={{
        // mb: 3.5,
        borderRadius: 1,
        color: "text.primary",
        p: "4px 16px !important",
        backgroundColor: bgColors.primaryLight.backgroundColor,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          width="20px"
          height="20px"
          src={ConfigApi.PUBLIC_IMAGES + net.logo + ".png"}
        />
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          {net.name}
        </Typography>
      </Box>
    </Box>
  );
}

export default observer(AddressItem);
