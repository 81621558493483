import { useState, useEffect, forwardRef } from "react";
// Mui
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// Libs
import DatePicker, { registerLocale } from "react-datepicker";
// ** Styled Component
import DatePickerWrapper from "src/@core/styles/libs/react-datepicker";

// moment
import moment from "moment";
import "moment/locale/ru";

// Custom components
import CurrencySelect from "src/components/CurrencySelect";
// ** Icon
import Icon from "src/@core/components/icon";
// Services
import CurrenciesService from "src/service/Currencies";

import WEBSITES from "src/configs/websites";
// const

const STATUSES = [
  {
    name: "Все",
    value: "",
  },
  {
    name: "Выполненные",
    value: "resolve",
  },
  {
    name: "Отклоненные",
    value: "reject",
  },
  {
    name: "В процессе",
    value: "procces",
  },
];

const CustomInput = forwardRef(({ ...props }, ref) => {
  // ** Props
  const { label, readOnly, inputProps = {}, date } = props;

  const formatDate = date ? moment(date).format("DD.MM.yyyy HH:mm") : "";

  return (
    <TextField
      fullWidth
      inputRef={ref}
      InputProps={inputProps}
      {...props}
      label={label || ""}
      {...(readOnly && { inputProps: { readOnly: true, ...inputProps } })}
      value={formatDate}
    />
  );
});

function AdvancedFilterDialog({
  show,
  handleClose,
  setFilterData,
  initFilterData = {},
  closeModal,
}) {
  // States
  const [currencies, setCurrencies] = useState([]);
  const [filter, setFilter] = useState(initFilterData);

  // Hooks
  useEffect(() => {
    if (currencies.length) return;
    CurrenciesService.getList({}).then((r) => setCurrencies(r.data));
  }, [show]);

  // Handlers
  const changeFilterData = (name, value) => {
    setFilter(Object.assign({}, filter, { [name]: value }));
  };

  const closeHandler = () => {
    handleClose();
    setFilter(initFilterData);
  };

  const searchHandler = () => {
    setFilterData(filter);
    closeModal();
  };

  return (
    <Dialog
      fullWidth
      open={show}
      maxWidth="sm"
      scroll="body"
      onClose={closeHandler}
      onBackdropClick={closeHandler}
      //   TransitionComponent={Transition}
    >
      <DialogContent
        sx={{
          pb: 6,
          px: { xs: 8, sm: 15 },
          pt: { xs: 8, sm: 12.5 },
          position: "relative",
        }}
      >
        <IconButton
          size="small"
          onClick={closeHandler}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon icon="mdi:close" />
        </IconButton>
        <Box sx={{ mb: 4, textAlign: "center" }}>
          <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
            Расширенный поиск
          </Typography>

          <Typography variant="body2">
            Поиск работает по принципу совпадения букв, слов и тд.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="select-site">Сайт</InputLabel>
              <Select
                value={filter.site}
                label="Сайт"
                fullWidth
                onChange={(e) => changeFilterData("site", e.target.value)}
                labelId="select-site"
              >
                {[
                  {
                    name: "Все",
                    value: "",
                  },
                  ...WEBSITES.map((s) => ({ name: s.name, value: s.id })),
                ].map((s) => {
                  return <MenuItem key={s.value} value={s.value}>{s.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="select-status">Статус</InputLabel>
              <Select
                value={filter.status}
                label="Статус"
                fullWidth
                onChange={(e) => changeFilterData("status", e.target.value)}
                labelId="select-status"
              >
                {STATUSES.map((s) => {
                  return <MenuItem key={s.value} value={s.value}>{s.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Заявка"
              placeholder="Введите номер заявки"
              id="order"
              type="number"
              onChange={(e) => changeFilterData("order", e.target.value)}
              value={filter.order}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              placeholder="Введите email клиента"
              id="email"
              type="text"
              onChange={(e) => changeFilterData("email", e.target.value)}
              value={filter.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Имя"
              placeholder="Введите имя клиента"
              id="name"
              type="text"
              onChange={(e) => changeFilterData("name", e.target.value)}
              value={filter.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Реквизит"
              placeholder="Имя, номер карты, номер телефона или адрес кошелька"
              id="requisite"
              type="text"
              onChange={(e) => changeFilterData("requisite", e.target.value)}
              value={filter.requisite}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="IP"
              placeholder="000.000.000.000"
              id="ip"
              type="text"
              onChange={(e) => changeFilterData("ip", e.target.value)}
              value={filter.ip}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ mb: 3 }}
              fullWidth
              label="Комментарий"
              placeholder="Текст комментария"
              id="comment"
              type="text"
              onChange={(e) => changeFilterData("comment", e.target.value)}
              value={filter.comment}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CurrencySelect
              label="Покупаю"
              placeholder="Выбирете валюту"
              value={filter.buy}
              setValue={(v) => changeFilterData("buy", v)}
              currencies={currencies}
              addEmpty
              emptyName="Не выбарно"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CurrencySelect
              emptyName="Не выбарно"
              label="Продаю"
              placeholder="Выбирете валюту"
              value={filter.sell}
              setValue={(v) => changeFilterData("sell", v)}
              currencies={currencies}
              addEmpty
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePickerWrapper
              sx={{ "& .react-datepicker-wrapper": { width: "100%" } }}
            >
              <DatePicker
                showTimeSelect
                locale="ru"
                timeFormat="HH:mm"
                timeIntervals={15}
                selected={filter.from}
                id="dtp-from"
                dateFormat="dd.mm.yyyy HH:mm"
                onChange={(v) => changeFilterData("from", moment(v).valueOf())}
                customInput={
                  <CustomInput
                    date={filter.from}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              changeFilterData("from", "");
                            }}
                            edge="end"
                          >
                            <Icon icon="ic:baseline-clear" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="От"
                  />
                }
              />
            </DatePickerWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePickerWrapper
              sx={{ "& .react-datepicker-wrapper": { width: "100%" } }}
            >
              <DatePicker
                showTimeSelect
                locale="ru"
                timeFormat="HH:mm"
                timeIntervals={15}
                selected={filter.to}
                id="dtp-to"
                dateFormat="dd.mm.yyyy HH:mm"
                onChange={(v) => changeFilterData("to", moment(v).valueOf())}
                customInput={
                  <CustomInput
                    date={filter.to}
                    label="До"
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              changeFilterData("to", "");
                            }}
                            edge="end"
                          >
                            <Icon icon="ic:baseline-clear" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
            </DatePickerWrapper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}>
        <Button variant="contained" sx={{ mr: 2 }} onClick={searchHandler}>
          Поиск
        </Button>
        <Button variant="outlined" color="secondary" onClick={closeHandler}>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdvancedFilterDialog;
