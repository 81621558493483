import { useState, useEffect, Fragment } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { CircularProgress, IconButton, Link } from "@mui/material";

import Alert from "@mui/material/Alert";

// import { Link } from "react-router-dom";
// ** Icon Imports
import Icon from "src/@core/components/icon";

// Modules
import Fields from "./Modules/Fields";
import SumTitle from "./Modules/SumTitle";
import WalletInfo from "./Modules/WalletInfo";
import ChangeIncomeRequisite from "./Modules/ChangeIncomeRequisite";
import ChangeIncomeSum from "./Modules/ChangeIncomeSum";
import QrImage from "./QrImage";
import CurrencyBalance from "./Modules/CurrencyBalance";

// Toast
import { toast } from "react-hot-toast";

// Service
import OrderService from "src/service/Order";

// Utils
import { getFullBlockchainTransactionUrl } from "src/utils/currency/getFullBlockchainTransactionUrl";

function StepIncoming(props) {
  let { order, disabledComponent = false } = props;

  // States
  let [view, setView] = useState("");
  let [isSubmit, setIsSubmit] = useState("");

  // let [confirmIncomeSubmitting, setConfirmIncomeSubmitting] = useState(false);
  // let [notIncomeSubmitting, setNotIncomeSubmitting] = useState(false);
  // let [cancelIncomeSubmitting, setCancelIncomeSubmitting] = useState(false);

  // Calc
  let userConfirmPayment = order.status == 103;
  let serviceConfirmPayment = order.status == 104;
  let notRejectedOrder = order.status !== 106;

  let amount = order.amount;
  let takeCurrency = order.currency.take;
  let isCurrentStep = order.status == 102 || order.status == 103;

  let statusCode = order.status;

  // Handlers
  const confirmIncomePaymentHandler = () => {
    setIsSubmit("confirmIncome");
    OrderService.confirmIncome(order._id)
      .then(() => {
        toast.success("Получение средств от клиента подтверждено!");
      })
      .finally(() => {
        setIsSubmit("");
      });
  };

  const notIncomePaymentHandler = () => {
    setIsSubmit("notIncome");
    OrderService.notIncome(order._id)
      .then(() => {
        toast.success("Не получение средств от клиента подтверждено!");
      })
      .finally(() => {
        setIsSubmit("");
      });
  };

  const cancelIncomePaymentHandler = () => {
    setIsSubmit("cancelIncome");
    OrderService.cancelIncome(order._id)
      .then(() => {
        toast.success("Поступление средств отмененно!");
      })
      .finally(() => {
        setIsSubmit("");
      });
  };

  let addedStyles = {};

  if (disabledComponent) {
    addedStyles = {
      "pointer-events": "none",
      cursor: "default",
      opacity: 0.32,
    };
  }

  let takeIsQr =
    takeCurrency?.currency && takeCurrency?.currency?.specific == "qr";

  let transLink = order?.currency?.take.currency?.info?.transLink;

  let takeAddress = order.fields.req.find((f) => f.validation == "address");

  let fullLinkBlockchainTransaction = getFullBlockchainTransactionUrl(
    transLink,
    takeAddress?.value
  );

  let transactionComponent = null;

  if (fullLinkBlockchainTransaction) {
    transactionComponent = (
      <Box
        sx={{
          display: "flex",
          overflow: "hidden",
          alignItems: "center",
          maxWidth: "100%",
        }}
      >
        <Typography sx={{ mr: 2 }} variant="body2">
          Транзакция:
        </Typography>
        <Link
          component={"a"}
          target="_blank"
          color="primary"
          underline="none"
          rel="noreferrer"
          variant="body1"
          sx={{ wordBreak: "break-word" }}
          href={fullLinkBlockchainTransaction}
        >
          {fullLinkBlockchainTransaction}
        </Link>
      </Box>
    );
  }

  let controllerComponent = (
    <>
      <Divider sx={{ my: "0px !important" }} />
      <CardContent sx={{ p: 3 }}>
        <Grid container spacing={4}>
          {isCurrentStep && (
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{ fontSize: "0.8rem" }}
                variant="contained"
                color="primary"
                onClick={confirmIncomePaymentHandler}
                disabled={isSubmit == "confirmIncome"}
                startIcon={
                  isSubmit == "confirmIncome" ? <CircularProgress /> : null
                }
              >
                Подтвердить поступление
              </Button>
            </Grid>
          )}
          {isCurrentStep && (
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{ fontSize: "0.8rem" }}
                variant="outlined"
                color="primary"
                onClick={() => setView("changeSum")}
              >
                Изменить сумму
              </Button>
            </Grid>
          )}
          {isCurrentStep && (
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{ fontSize: "0.8rem" }}
                variant="contained"
                color="error"
                onClick={notIncomePaymentHandler}
                disabled={isSubmit == "notIncome"}
                startIcon={
                  isSubmit == "notIncome" ? <CircularProgress /> : null
                }
              >
                Средства не поступили
              </Button>
            </Grid>
          )}
          {serviceConfirmPayment && (
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{ fontSize: "0.8rem" }}
                variant="contained"
                color="error"
                onClick={cancelIncomePaymentHandler}
                disabled={isSubmit == "cancelIncome"}
                startIcon={
                  isSubmit == "cancelIncome" ? <CircularProgress /> : null
                }
              >
                Отменить поступление
              </Button>
            </Grid>
          )}
          {notRejectedOrder && (
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{ fontSize: "0.8rem" }}
                variant="outlined"
                color="primary"
                onClick={() => setView("changeRequisite")}
              >
                Сменить реквизит
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </>
  );

  let showComponent;

  if (view == "") {
    showComponent = controllerComponent;
  }

  let isAlertSumStatus = getAlertSumStatus(
    order.amount.take,
    order.status,
    takeCurrency?.code,
    takeCurrency?.currency?.isCrypto
  );

  // console.log(takeCurrency, "takeCurrency")

  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default, ...addedStyles }}>
      <CardContent sx={{ p: 3 }}>
        {userConfirmPayment && (
          <Alert sx={{ p: "4px 10px", mb: 2 }} severity="info">
            Пользователь подтвердил отправление средств.
          </Alert>
        )}

        {isAlertSumStatus && (
          <Alert
            icon={<Icon icon="ep:coin" />}
            variant="outlined"
            sx={{ p: "4px 10px", mb: 2 }}
            severity="error"
          >
            Проверьте точную сумму поступления!
          </Alert>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <SumTitle text={"Сумма"} sum={amount.take} code={takeCurrency.code} />
          {!!amount.old && (
            <SumTitle
              old
              text={"Старая сумма"}
              sum={amount?.old?.take}
              code={takeCurrency.code}
            />
          )}
        </Box>
      </CardContent>
      <Divider sx={{ my: "0px !important" }} />
      <CardContent sx={{ p: 3 }}>
        {!!takeIsQr && <QrImage orderId={order._id} />}

        <Typography variant="body1">Реквизиты клиента:</Typography>

        <Fields list={order.fields.take} style={{ mt: 2 }} />
      </CardContent>
      <Divider sx={{ my: "0px !important" }} />
      <CardContent sx={{ p: 3 }}>
        <Typography variant="body1">Реквизиты сервиса:</Typography>
        <Box sx={{ my: 3 }}>
          {order?.incomeInfo?.kitName && (
            <WalletInfo name="Комплект" value={order.incomeInfo.kitName} />
          )}
          {/* <WalletInfo name="Комплект" value={order.incomeInfo.kitName} /> */}
          {order?.incomeInfo?.requisiteName && (
            <WalletInfo
              name="Реквизит"
              value={order?.incomeInfo?.requisiteName}
            />
          )}
        </Box>

        <Fields
          hasPaymentLinks={!!order?.paymentLinks?.length}
          isIncome
          list={order.fields.req}
          style={{ mt: 2 }}
        />
        {transactionComponent}
      </CardContent>

      {showComponent}

      {notRejectedOrder && view == "changeRequisite" && (
        <ChangeIncomeRequisite
          orderId={order._id}
          handleClose={() => setView("")}
          sum={order.amount.take}
          currency={order?.currency?.take?.currency?._id}
          requisiteId={order.incomeInfo.requisite}
          isCrypto={order?.currency?.take?.currency?.isCrypto}
          code={order?.currency?.take?.currency?.code}
        />
      )}

      {order.oldIncomeInfo && order?.fields?.oldReq && (
        <OldWalletInfo
          oldIncomeInfo={order.oldIncomeInfo}
          oldReq={order?.fields?.oldReq}
        />
      )}
      {isCurrentStep && view == "changeSum" && (
        <ChangeIncomeSum
          orderId={order._id}
          handleClose={() => setView("")}
          oldSum={order.amount.take}
        />
      )}
      {statusCode == 104 || statusCode == 105 ? (
        <CurrencyBalance orderId={order._id} />
      ) : null}
    </Card>
  );
}

function OldWalletInfo(props) {
  let { oldIncomeInfo = {}, oldReq = [] } = props;

  let [show, setShow] = useState(false);

  return (
    <Fragment>
      <Divider sx={{ my: "0px !important" }} />
      <CardContent sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="error" variant="body1">
            Прежние реквизиты:
          </Typography>
          <IconButton sx={{ p: 1 }} onClick={() => setShow(!show)}>
            <Icon icon={show ? "mdi:chevron-up" : "mdi:chevron-down"} />
          </IconButton>
        </Box>
        <Collapse in={show} timeout="auto" unmountOnExit>
          <Box sx={{ my: 3 }}>
            <WalletInfo name="Комплект" value={oldIncomeInfo.kitName} />
            {oldIncomeInfo.requisiteName && (
              <WalletInfo name="Реквизит" value={oldIncomeInfo.requisiteName} />
            )}
          </Box>
          <Fields old list={oldReq} style={{ mt: 2 }} />
        </Collapse>
      </CardContent>
    </Fragment>
  );
}

function getAlertSumStatus(sum, status, code, isCrypto) {
  let isUsdCrypto = code.includes("USD") && isCrypto;
  let isMultiple10 = Math.floor(sum) % 10 !== 0;
  let isBigSum = Math.floor(sum) >= 1000;
  let isNotPaymentStatus = status == 102 || status == 103;
  return isUsdCrypto && isMultiple10 && isNotPaymentStatus && isBigSum;
}

{
  /* <div v-if="showChangeRequisite">
                    <div>
                        <div class="label">Выберите реквизиты :</div>
                        <ul>
                            <li v-for="kit in kits" @click="showRequisites === kit._id ? (showRequisites = '') : (showRequisites = kit._id)" :key="kit._id">
                                <div class="">
                                    <div class="kitName">
                                        <span class="label">Комп.</span> <span class="value">{{ kit.name }}</span>
                                        <div class="spacer"></div>
                                        <span class="label">Баланс:</span> <span class="value">{{ kit.balance }}</span>
                                    </div>

                                    <div v-if="showRequisites === kit._id && !kit.crypto" class="select">
                                        <div class="requisites" @click="selectChangeRequisite(requisite)" v-for="requisite in kit.requisites" :key="requisite._id">
                                            <div>Реквизит: {{ requisite.name }}</div>
                                            <div>Баланс: {{ requisite.balance }}</div>
                                        </div>
                                    </div>
                                    <div v-if="showRequisites === kit._id && kit.crypto" class="select">
                                        <div class="requisites" @click="selectChangeRequisite(requisite)" v-for="requisite in kit.requisites" :key="requisite._id">
                                            <div>Адрес: {{ requisite.name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> */
}

{
  /* <div class="step-action" v-if="isNew || isPaymentUser">
                    <div class="btnBox">

                        <div v-if="!showDirection" class="editAmount">
                            <my-button class="outline" value="Изменить сумму" @click.native="showChangeAmount = !showChangeAmount"></my-button>

                            <div v-if="showChangeAmount" class="changeAmount">
                                <my-input type="text" v-model.trim="order.amount.take"></my-input>
                                <svg v-if="showSaveAmount" class="save-icon" @click="changeAmount()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-2.5 -5.5 24 24" fill="none" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div> */
}

export default StepIncoming;
