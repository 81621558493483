import axios from "axios";

// /exchange/excluded-target

class Exchange {
  //

  findGeneralExchange(params){
    return axios.get("/exchange/general-find", {
      params
    });
  }

  getExchangeCoursesFilter(){
    return axios.get("/exchange/filter");
  }
  // 
  changeGroupExchangeEnabled(data) {
    return axios.put("/exchange/group-toggle", data);
  }
  addExchange(data) {
    return axios.post("/exchange", data);
  }
  addAllExchange(data) {
    return axios.post("/exchange/all", data);
  }
  getExchangeList(params) {
    return axios.get("/exchange", { params });
  }
  changeExchange(data) {
    return axios.put("/exchange", data);
  }
  changeListExchange(data) {
    return axios.put("/exchange/list", data);
  }
  getOneFullExchang(id) {
    return axios.get("/exchange/full", { params: { id } });
  }
  //Exchange target
  getExchangeTarget(exchangeId) {
    return axios.get(`/exchange/${exchangeId}/target`);
  }
  changeExchangeTarget(exchangeId, data) {
    return axios.put(`/exchange/${exchangeId}/target`, data);
  }
  deleteExchangeTarget(exchangeId) {
    return axios.delete(`/exchange/${exchangeId}/target`);
  }

  // Excluded target
  getAllExcludedTarget() {
    return axios.get(`/exchange/excluded-target`);
  }
  crudExcludeTarget(method, data) {
    return axios.post(`/exchange/excluded-target`, { method, data });
  }

  // "/exchange/:id/target"
}

export default new Exchange();
