import axios from "axios";

class Order {
  create(data) {
    return axios.post("/order", data);
  }
  getList(params) {
    return axios.get("/order", { params });
  }
  getSearchList(params) {
    return axios.get("/order/search", { params });
  }
  getOne(id) {
    return axios.get(`/order/${id}`);
  }
  changeArchive(id) {
    return axios.put(`/order/archive/${id}`);
  }
  changeCommon(id, data) {
    return axios.put(`/order/common/${id}`, data);
  }
  confirmIncome(id) {
    return axios.put(`/order/confirm-income/${id}`);
  }
  notIncome(id) {
    return axios.put(`/order/not-income/${id}`);
  }
  cancelIncome(id) {
    return axios.put(`/order/cancel-income/${id}`);
  }
  confirmInWork(id) {
    return axios.put(`/order/confirm-in-work/${id}`);
  }
  confirmSend(id, data) {
    return axios.put(`/order/confirm-send/${id}`, data);
  }
  changeCommission(id, data) {
    return axios.put(`/order/change-commission/${id}`, data);
  }
  changeIncomeSum(id, sum) {
    return axios.put(`/order/change-sum/${id}`, { sum });
  }

  restore(id) {
    return axios.put(`/order/restore/${id}`);
  }
  refund(id) {
    return axios.put(`/order/refund/${id}`);
  }

  checked(id) {
    return axios.put(`/order/checked/${id}`);
  }

  changeRequisite(id, requisiteId) {
    return axios.put(`/order/change-requisite/${id}`, { requisiteId });
  }

  changeCharge(id, data) {
    return axios.put(`/order/change-charge/${id}`, data);
  }

  // QR
  getQr(id) {
    return axios.get(`/order/qr/${id}`);
  }
  sendQr(id, image) {
    return axios.post(`/order/qr/${id}`, { image });
  }
  changeDebt(data) {
    return axios.put(`/order/debt`, data);
  }

  getWalletSnapshot(id) {
    return axios.get(`/order/${id}/wallet-snapshot`);
  }

  //
  //
}

export default new Order();
