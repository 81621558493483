import { useState, useEffect } from "react";
// Mui
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

// Config
import ConfigApi from "src/configs/api";

// Toast
import { toast } from "react-hot-toast";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

// Configs
import WEBSITESANDSERVICES from "src/configs/websitesAndServices";

// Services
import SiteCurrencyService from "src/service/SiteCurrency";
import ExchangeService from "src/service/Exchange";

// Socket
import { socket } from "src/socket";

// Modules
import SelectBaseCurrency from "src/modules/Courses/CourseSelectBase";
import AddExchangeModal from "./AddExchangeModal";

// Helpers
import exchangeSearchFiltering from "src/helpers/exchangeSearchFiltering";

// show, closeHandler, site,

function SiteCourses() {
  // State
  const [currentTab, setCurrentTab] = useState(WEBSITESANDSERVICES[0].id);

  return (
    <Card>
      <CardHeader title={"Курсы сайтов"} />
      <CardContent>
        <TabContext value={currentTab}>
          <TabList
            onChange={(_, v) => setCurrentTab(v)}
            variant="scrollable"
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {WEBSITESANDSERVICES.map((s) => (
              <Tab value={s.id} label={s.name} />
            ))}
          </TabList>
          {WEBSITESANDSERVICES.map((s) => {
            return (
              <TabPanel value={s.id}>
                <SiteTabPanel
                  site={s.id}
                  //   currencies={e.currencies}
                  //   code={e.code}
                  //   setAddCurrencyShow={openAddCurrencyShow}
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </CardContent>
    </Card>
  );
}

function SiteTabPanel(props) {
  let { site } = props;
  // State

  let [search, setSearch] = useState("");
  let [baseCurrencies, setBaseCurrencies] = useState([]);
  let [selectedBaseId, setSelectedBaseId] = useState("");

  // Hooks
  useEffect(() => {
    SiteCurrencyService.getSiteCurrencyList({ sc: site }).then((r) =>
      setBaseCurrencies(r.data)
    );
    socket.on("site_currency_add", (c) =>
      setBaseCurrencies((list) => [c, ...list])
    );
    return () => {
      socket.off("site_currency_add");
    };
  }, [site]);

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <SelectBaseCurrency
            currencies={baseCurrencies}
            selectedId={selectedBaseId}
            setSelectedId={setSelectedBaseId}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {selectedBaseId && (
            <SiteCoursList site={site} baseId={selectedBaseId} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

function SiteCoursList({ site, baseId }) {
  // State
  let [showAddOne, setShowAddOne] = useState(false);
  let [exchanges, setExchanges] = useState([]);
  let [search, setSearch] = useState("");
  let [isSubmitingAddAll, setIsSubmitingAddAll] = useState(false);

  // Hooks
  useEffect(() => {
    ExchangeService.getExchangeList({
      sc: site,
      tk: baseId,
    }).then((r) => {
      setExchanges(r.data);
    });
  }, [site, baseId]);

  // Handlers
  const addExchangeHandler = (e) => {
    setExchanges((list) => [e, ...list]);
  };
  const addAllExchangeHandler = () => {
    setIsSubmitingAddAll(true);
    ExchangeService.addAllExchange({
      sc: site,
      base: baseId,
    })
      .then((r) => {
        setExchanges((list) => [...r.data, ...list]);
        toast.success("Обменные пары добавлены!");
      })
      .finally(() => setIsSubmitingAddAll(false));
  };

  // Calc
  let filteringExchanges = exchangeSearchFiltering(exchanges, search);

  let hasExchanges = filteringExchanges.length > 0;

  return (
    <Box>
      <AddExchangeModal
        addExchangeHandler={addExchangeHandler}
        show={showAddOne}
        closeHandler={() => setShowAddOne(false)}
        site={site}
        baseId={baseId}
      />

      <Grid container spacing={4}>
        <Grid item xs={6} md={3}>
          <Button
            fullWidth
            variant="contained"
            size="medium"
            onClick={() => setShowAddOne(true)}
          >
            Добавить
          </Button>
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            fullWidth
            variant="contained"
            size="medium"
            startIcon={
              isSubmitingAddAll ? <CircularProgress size="1rem" /> : null
            }
            disabled={isSubmitingAddAll}
            onClick={addAllExchangeHandler}
          >
            Для всех
          </Button>
        </Grid>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={12} md={3}>
          <TextField
            size="small"
            fullWidth
            value={search}
            placeholder="Поиск котируемой валюты"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>
      {hasExchanges && <ToggleEnablExchange sc={site} baseId={baseId} />}

      <List
        dense
        sx={{
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        {filteringExchanges.map((e, i) => (
          <ExchangCard key={e._id} data={e} />
        ))}
      </List>
    </Box>
  );
}

function ToggleEnablExchange({ sc, baseId }) {
  const [submitBtnCode, setSubmitBtnCode] = useState("");

  //  Handlers

  const enabledAllHandler = () => {
    changeToggleExchangeApi("enabled", true);
  };
  const disabledAllHandler = () => {
    changeToggleExchangeApi("disabled", false);
  };

  // API
  const changeToggleExchangeApi = (code, enabled) => {
    setSubmitBtnCode(code);
    ExchangeService.changeGroupExchangeEnabled({
      sc,
      baseId,
      enabled,
    })
      .then(() => {
        toast.success(
          `Все пары ${code == "enabled" ? "включены" : "отключены"}!`
        );
      })
      .finally(() => setSubmitBtnCode(""));
  };

  return (
    <Grid sx={{ mt: 2, justifyContent: "flex-end" }} container spacing={4}>
      <Grid item xs={6} md={3}>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          startIcon={
            submitBtnCode == "enabled" && <CircularProgress size="1rem" />
          }
          disabled={submitBtnCode}
          onClick={enabledAllHandler}
        >
          Вкл все
        </Button>
      </Grid>
      <Grid item xs={6} md={3}>
        <Button
          fullWidth
          color="error"
          variant="outlined"
          size="medium"
          startIcon={
            submitBtnCode == "disabled" && <CircularProgress size="1rem" />
          }
          disabled={submitBtnCode}
          onClick={disabledAllHandler}
        >
          Выкл все
        </Button>
      </Grid>
    </Grid>
  );
}

function ExchangCard(props) {
  let { data } = props;
  let [exchange, setExchange] = useState(data);

  let main = exchange.gv.cur;

  // Hooks
  useEffect(() => {
    socket.on(`exchange_upd_${exchange._id}`, (d) => {
      setExchange(d);
    });
    return () => {
      socket.off(`exchange_upd_${exchange._id}`);
    };
  }, []);

  // Handlers
  const changeExchangeEnblHandler = (enbl) => {
    let fetchObj = {
      _id: exchange._id,
      enbl,
    };
    ExchangeService.changeExchange(fetchObj).then((r) =>
      toast.success(`Пара ${enbl ? "включена" : "отключена"}!`)
    );
  };

  return (
    <ListItem>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 46, height: 46, mr: 3 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + main.image + ".png"}
          alt={main.name}
          sx={{ height: 36, width: 36 }}
        />
      </CustomAvatar>
      <ListItemText primary={main.name} secondary={main.code} />
      <ListItemSecondaryAction>
        <Checkbox
          edge="end"
          tabIndex={-1}
          disableRipple
          onChange={(v) => changeExchangeEnblHandler(v.target.checked)}
          checked={exchange.enbl}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default SiteCourses;
