import { useState, useEffect } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Toast
import { toast } from "react-hot-toast";

// Service
import CurrencyService from "src/service/Currencies";

// Form
import { useForm, Controller } from "react-hook-form";

// Constants
import CONSTANTS from "src/constants/index";

function BasicSettings(props) {
  let { currency } = props;
  // State
  let [filter, setFilter] = useState(currency.filter);
  let [isSubmiting, setSubmiting] = useState(false);
  let [isMultinet, setIsMultinet] = useState(!!currency.isMultinet);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: currency.name,
      code: currency.code,
      netName: currency.netName,
      originalName: currency.originalName,
      precision: currency.precision,
      specific: currency.specific || "",
      cms: currency.cms || 0,
    },
  });

  // Handlers
  const onSubmit = handleSubmit((data) => {
    let fetchObj = {
      _id: currency._id,
      ...data,
      filter,
    };
    setSubmiting(true);
    CurrencyService.changeCurrency(fetchObj)
      .then((r) => {
        toast.success("Основные настройки валюты измененны!");
      })
      .finally(() => setSubmiting(false));
  });

  return (
    <Card>
      <CardHeader title="Основные настройки" />
      <Divider sx={{ m: "0 !important" }} />
      <CardContent>
        <form noValidate onSubmit={onSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Название"
                    placeholder="Название"
                    size="small"
                    id="name"
                    type="text"
                    error={Boolean(errors.name?.message)}
                    {...register("name", {
                      required: "Обязательное поле!",
                    })}
                    helperText={errors.name?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"code"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Код"
                    placeholder="Введите код валюты"
                    size="small"
                    id="code"
                    type="text"
                    error={Boolean(errors.code?.message)}
                    {...register("code", {
                      required: "Обязательное поле!",
                    })}
                    helperText={errors.code?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"precision"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Точность"
                    placeholder="От 2 до 8"
                    id="precision"
                    type="number"
                    error={Boolean(errors.precision?.message)}
                    {...register("precision", {
                      required: "Обязательное поле!",
                      min: {
                        value: 2,
                        message: "Мин. значение 2",
                      },
                      max: {
                        value: 8,
                        message: "Макс. значение 8",
                      },
                    })}
                    helperText={errors.precision?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl size="small" fullWidth>
                <InputLabel id="filterLabel">Фильтр</InputLabel>
                <Select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  size="small"
                  label="Фильтр"
                  id="filter"
                  labelId="filterLabel"
                >
                  {CONSTANTS.currencyFilter.map((f) => (
                    <MenuItem key={f.value} value={f.value}>
                      {f.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"specific"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Специфическая валюта"
                    placeholder="Введите код специфической валюты"
                    id="specific"
                    type="text"
                    error={Boolean(errors.specific?.message)}
                    {...register("specific", {})}
                    helperText={errors.specific?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"cms"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Комиссия"
                    placeholder="%"
                    id="cms"
                    type="number"
                    error={Boolean(errors.cms?.message)}
                    {...register("cms", {})}
                    helperText={errors.cms?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"netName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Название сети, слоя, обертки..."
                    placeholder="TRC 20, BEP 20 "
                    id="netName"
                    type="string"
                    error={Boolean(errors.netName?.message)}
                    {...register("netName", {})}
                    helperText={errors.netName?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"originalName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Оригинальное название"
                    placeholder="bitcoin, ethereum"
                    id="originalName"
                    type="string"
                    error={Boolean(errors.originalName?.message)}
                    {...register("originalName", {})}
                    helperText={errors.originalName?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
            <Button
              endIcon={<Icon icon="material-symbols:save-outline" />}
              variant="contained"
              onClick={onSubmit}
            >
              Сохранить
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}

export default BasicSettings;
