import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";

// Config
import ConfigApi from "src/configs/api";
// const avatarGroupStyle = { width: `24px`, height: `24px` };

export default function CurrencyIconList({ directions }) {
  return (
    <AvatarGroup
      sx={{
        "& .MuiAvatar-root": { width: 24, height: 24 },
      }}
      spacing={6}
      max={7}
    >
      {directions.map((d) => {
        return (
          <Avatar
            sx={{ width: 24, height: 24 }}
            alt={d.name}
            src={ConfigApi.PUBLIC_IMAGES + d.image + ".png"}
          />
        );
      })}
    </AvatarGroup>
  );
}
