import { createContext, useEffect, useState } from "react";

import { globalNotificationService } from "src/service/GlobalNotification";

import { socket } from "src/socket";

const GlobalNotificationContext = createContext(null);

const GlobalNotificationProvider = ({ children }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    globalNotificationService.getAll().then((r) => setList(r.data));
    socket.on(`global_notification_add`, (obj) => {
      setList((list) => [obj, ...list]);
    });
    socket.on(`global_notification_delete`, (id) => {
      setList((list) => list.filter((i) => i._id !== id));
    });
    return () => {
      socket.off(`global_notification_add`);
      socket.off(`global_notification_delete`);
    };
  }, []);

  // console.log(list, "GlobalNotificationProvider");

  const findByOrderId = (id) => {
    return list.find((item) => item.data === id);
  };

  const values = {
    list,
    findByOrderId,
  };

  return (
    <GlobalNotificationContext.Provider value={values}>
      {children}
    </GlobalNotificationContext.Provider>
  );
};

export { GlobalNotificationContext, GlobalNotificationProvider };
