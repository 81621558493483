import { useEffect, useState } from "react";
// Mui
import { Box, CircularProgress, Grid, Button } from "@mui/material";

// Modules
import TargetPairCard from "./TargetPairCard";
// Services
import TargetPairService from "src/service/TargetPair";

import { Icon } from "@iconify/react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import toast from "react-hot-toast";

// Event
// import emitter from "src/EventEmitter";

function TargetPairList({ statusFilter, takeId, giveId, isAllFilter = false }) {
  let [list, setList] = useState([]);
  let [sortList, setSortList] = useState([]);

  let [loaded, setLoaded] = useState(true);
  let [isSorting, setIsSorting] = useState(false);

  // // Hooks
  useEffect(() => {
    if (statusFilter !== "all") return;
    setLoaded(true);
    TargetPairService.getPairTargetFull({
      filter: statusFilter,
    })
      .then((r) => {
        setList(r.data);
      })
      .finally(() => setLoaded(false));
  }, [statusFilter]);

  // // Hooks
  useEffect(() => {
    if (statusFilter !== "pair") return;
    let hasPair = takeId && giveId;
    if (!hasPair) return;
    if (takeId == giveId) return;
    setLoaded(true);
    TargetPairService.getPairTargetFull({
      take: takeId,
      give: giveId,
    }).then((r) => {
      setList(r.data);
      setLoaded(false);
    });
  }, [takeId, giveId, statusFilter]);

  const deleteItemHandler = (id) => {
    setList((list) => list.filter((t) => t._id !== id));
  };

  const startSortingHandler = () => {
    setSortList(list.sort((a, b) => a.srt - b.srt));
    setIsSorting(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const list = reorder(
      sortList,
      result.source.index,
      result.destination.index
    );
    setSortList(list);
  };

  const saveSortHandler = () => {
    let pairList = sortList.map((c, i) => {
      return Object.assign({}, c, { srt: i + 1 });
    });
    TargetPairService.sortPairs({
      sort: pairList.map((c) => ({ _id: c._id, srt: c.srt })),
    }).then(() => {
      toast.success("Пары отсортированны!");
      setList(pairList);
      setIsSorting(false);
    });
  };

  let sortedList = [...list];
  sortedList.sort((a, b) => a.srt - b.srt);

  let listRender = sortedList.map((p) => (
    <TargetPairCard
      deleteTargetPair={deleteItemHandler}
      style={{ mb: 4 }}
      key={p._id}
      data={p}
    />
  ));

  if (isSorting) {
    listRender = (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {sortList.map((p, index) => (
                <Draggable key={p._id} draggableId={p._id} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      deleteTargetPair={deleteItemHandler}
                      sx={{ mb: 4 }}
                      key={p._id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <TargetPairCard data={p} />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  const listComponent = (
    <Box>
      <Box>
        {isAllFilter && (
          <Grid sx={{ mb: 4 }} justifyContent="flex-end" container spacing={3}>
            {}
            {isSorting && (
              <>
                <Grid item xs={6} md={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="medium"
                    onClick={saveSortHandler}
                  >
                    Сохранить
                  </Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    fullWidth
                    color="error"
                    variant="outlined"
                    size="medium"
                    onClick={() => setIsSorting(false)}
                  >
                    Отменить
                  </Button>
                </Grid>
              </>
            )}
            {!isSorting && (
              <Grid item xs={6} md={3}>
                <Button
                  fullWidth
                  startIcon={<Icon icon="mdi:sort-numeric-descending" />}
                  variant="outlined"
                  size="medium"
                  onClick={startSortingHandler}
                >
                  Сортировать
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      {listRender}
    </Box>
  );

  return <Box>{loaded ? loadedComponent : listComponent}</Box>;
}

let loadedComponent = (
  <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
    <CircularProgress />
  </Box>
);

export default TargetPairList;
