export default function sortWallet(list) {
  let result = [...list];
  let enabled = result
    .filter((i) => i.enabled && !i.blocked)
    .sort((a, b) => (a.balance > b.balance ? -1 : 1))
    .sort((a, b) => (a.isFavorite && !b.isFavorite ? -1 : 1));
  let disabled = result
    .filter((i) => !i.enabled && !i.blocked)
    .sort((a, b) => (a.balance > b.balance ? -1 : 1));
  let blocked = result
    .filter((i) => i.blocked)
    .sort((a, b) => (a.balance > b.balance ? -1 : 1));
  return [...enabled, ...disabled, ...blocked];
}
