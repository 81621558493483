import { useState, useEffect } from "react";
// Mui
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Grid from "@mui/material/Grid";
// Custom Components
import PageWrap from "src/components/PageWrap";
//Route
import { useParams } from "react-router-dom";
// Configs
import WEBSITESANDSERVICES from "src/configs/websitesAndServices";
// Modules
import SelectBaseCurrency from "src/modules/Courses/CourseSelectBase";
import CourseCard from "./CourseCard";
import Сontrol from "./Сontrol";

// Services
import SiteCurrencyService from "src/service/SiteCurrency";
import ExchangeService from "src/service/Exchange";
// Utils
import exchangeSearchFiltering from "src/helpers/exchangeSearchFiltering";

function Courses() {
  // State
  let [baseCurrencies, setBaseCurrencies] = useState([]);
  let [selectedBaseId, setSelectedBaseId] = useState("");
  let [showTarget, setShowTarget] = useState(false);
  let [onlyTarget, setOnlyTarget] = useState(false);
  let [exchanges, setExchanges] = useState([]);
  let [quoteSearch, setQuoteSearch] = useState("");
  //   let [search, s]
  // Hooks
  const params = useParams();

  // Calc
  const site = WEBSITESANDSERVICES.find((s) => s.id === params.site);
  let filteringExchanges = exchangeSearchFiltering(exchanges, quoteSearch);
  filteringExchanges = filteringExchanges.filter((e) => {
    if (!onlyTarget) return true;
    return !!e.target;
  });
  let selectedBase = baseCurrencies.find((c) => c._id == selectedBaseId);

  // Hooks
  useEffect(() => {
    SiteCurrencyService.getSiteCurrencyList({ sc: site.id, enbl: true }).then(
      (r) => setBaseCurrencies(r.data)
    );
  }, [site.id]);
  
  useEffect(() => {
    if (!selectedBaseId) return;
    setExchanges([]);
    ExchangeService.getExchangeList({
      sc: site.id,
      tk: selectedBaseId,
      enbl: true,
    }).then((r) => setExchanges(r.data));
    return () => {
      setExchanges([]);
    };
  }, [site.id, selectedBaseId]);

  return (
    <PageWrap title={`Курсы (${site.name})`}>
      <Grid container spacing={4}>
        <Grid  item xs={12} md={3}>
          <Card sx={{position: 'sticky', top: '70px'}}>
            <CardContent>
              <SelectBaseCurrency
                currencies={baseCurrencies}
                selectedId={selectedBaseId}
                setSelectedId={setSelectedBaseId}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
          <Сontrol
            onlyTarget={onlyTarget}
            setOnlyTarget={setOnlyTarget}
            site={site.id}
            currency={selectedBase}
            quoteSearch={quoteSearch}
            setQuoteSearch={setQuoteSearch}
            showTarget={showTarget}
            setShowTarget={setShowTarget}
          />
          {filteringExchanges.map((e) => (
            <CourseCard
              key={e._id}
              data={e}
              showTarget={showTarget}
              style={{ mb: 3 }}
            />
          ))}
        </Grid>
      </Grid>
    </PageWrap>
  );
}

export default Courses;
