// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

function OrderChip(props) {
  let { status, size = "medium", style = {} } = props;

  return (
    <CustomChip
      skin="light"
      size={size}
      label={getStatusData(status).name}
      color={getStatusData(status).color}
      sx={{
        width: "min-content",
        fontSize: "1rem",
        fontWeight: 600,
        borderRadius: "5px",
        ...style,
      }}
    />
  );
}

function getStatusData(code) {
  switch (code) {
    case 101:
      return {
        color: "warning",
        name: "Верификация",
      };
    case 102:
      return {
        color: "warning",
        name: "Новая",
      };
    case 103:
      return {
        color: "info",
        name: "Оплачена",
      };
    case 104:
      return {
        color: "info",
        name: "Принята",
      };
    case 105:
      return {
        color: "success",
        name: "Выполненна",
      };
    case 106:
      return {
        color: "error",
        name: "Отклоненна",
      };
    case "oncheck":
      return {
        color: "warning",
        name: "Проверка",
      };
    case "onwork":
      return {
        color: "primary",
        name: "В работе",
      };
    case "problem":
      return {
        color: "secondary",
        name: "Проблемная",
      };
    default:
      return {
        color: "default",
        name: "Не определен",
      };
  }
}

export default OrderChip;
