import { useState, useEffect } from "react";
//Mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useTheme, useMediaQuery, CardContent, Card } from "@mui/material";

// ** Icon
import Icon from "src/@core/components/icon";

// Toast
import toast from "react-hot-toast";

// Draggable
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Services
import WalletService from "src/service/Wallet";

// Helpers
import sortKitsNumber from "src/helpers/sortKitsNumber";

// Modules
import SpecialIssueKit from "./SpecialIssueKit";

// Consts
const roundKits = [
  {
    name: "По очереди",
    value: "turn",
  },
  {
    name: "По наименьшему остатку",
    value: "smallest",
  },
];

function KitsWrap(props) {
  let {
    style = {},
    wallet,
    kits = [],
    currency,
    KitComponent,
    KitComponentProps = {},
  } = props;
  let [walletData, setWalletData] = useState(wallet);

  // States
  let [isSorting, setIsSorting] = useState(false);
  let [sortKits, setSortKits] = useState([]);

  // Calc
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // let sortedKits = sortKitsNumber(kits);

  // Handlers
  const changeRoundKitsHandler = (round) => {
    WalletService.changeWalletCurrency({
      _id: walletData._id,
      round,
    }).then((r) => {
      setWalletData(Object.assign({}, walletData, { ...r.data }));
      toast.success("Обход комплектов изменен!");
    });
  };

  const saveSortHandler = () => {
    let kitsList = sortKits.map((c, i) => {
      return Object.assign({}, c, { srt: i + 1 });
    });
    WalletService.sortKits({
      sort: kitsList.map((c) => ({ _id: c._id, srt: c.srt })),
      walletId: wallet._id,
    }).then(() => {
      toast.success("Комлекты отсортированны!");
      setIsSorting(false);
    });
  };

  // Drag and drop

  const startSortingHandler = () => {
    setSortKits(kits);
    setIsSorting(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const list = reorder(
      sortKits,
      result.source.index,
      result.destination.index
    );

    setSortKits(list);
  };

  let kitListComponent = kits.map((k) => {
    return (
      <KitComponent
        data={k}
        key={k._id}
        style={{ mb: 2 }}
        {...KitComponentProps}
      />
    );
  });

  if (isSorting) {
    kitListComponent = (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <Box
              sx={{ mt: 6 }}
              container
              spacing={4}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sortKits.map((k, index) => (
                <Draggable key={k._id} draggableId={k._id} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      key={k._id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <KitComponent
                        data={k}
                        key={k._id}
                        style={{ mb: 2 }}
                        {...KitComponentProps}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  if (!kits.length) return null;

  return (
    <Box sx={{ ...style }}>
      <Grid sx={{ mb: 3 }} justifyContent="space-between" container spacing={4}>
        <Grid item xs={6} md={3}>
          <FormControl
            size="small"
            fullWidth
            sx={{
              bgcolor: (t) => t.palette.background.paper,
            }}
          >
            <InputLabel>Обход</InputLabel>
            <Select
              size="small"
              label="Обход"
              defaultValue={walletData.round}
              id="kit-round"
              value={walletData.round}
              onChange={(e) => changeRoundKitsHandler(e.target.value)}
            >
              {roundKits.map((i) => (
                <MenuItem value={i.value} key={i.value}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          {isDesktop && (
            <Box>
              {isSorting ? (
                <Box sx={{ display: "flex" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="medium"
                    onClick={saveSortHandler}
                  >
                    Сохранить
                  </Button>

                  <Button
                    sx={{ ml: 4 }}
                    fullWidth
                    variant="outlined"
                    size="medium"
                    onClick={() => setIsSorting(false)}
                  >
                    Отменить
                  </Button>
                </Box>
              ) : (
                <Button
                  fullWidth
                  startIcon={<Icon icon="mdi:sort-numeric-descending" />}
                  variant="outlined"
                  size="medium"
                  onClick={startSortingHandler}
                >
                  Сортировать
                </Button>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid sx={{ mb: 3 }} container spacing={2}>
        <Grid item xs={6} md={3}>
          <SpecialIssueKit
            isCrypto={currency?.isCrypto}
            isTurnRound={walletData?.round === "turn"}
            walletId={wallet._id}
          />
          {/* <Card>
            <CardContent sx={{ p: "12px 18px !important" }}>режим</CardContent>
          </Card> */}
        </Grid>
      </Grid>

      <Box>{kitListComponent}</Box>
    </Box>
  );
}

export default KitsWrap;
