import { Box, Button, Grid } from "@mui/material";

import SimpleSelectCurrency from "src/components/Currency/SimpleSelectCurrency";

export default function SelectOtherCurrencies({
  currencyId,
  setCurrencyId,
  handleClose,
  currencies,
  
}) {
  //

  return (
    <Box sx={{ p: 3, pb: 1 }}>
      <SimpleSelectCurrency
        size="small"
        label="Выбор валюту"
        value={currencyId}
        currencies={currencies}
        onChange={setCurrencyId}
        containerStyle={{ mb: 3 }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            onClick={handleClose}
            size="small"
            fullWidth
            variant="outlined"
            color="error"
          >
            Отменить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
