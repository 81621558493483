import {ListItemButton, Box, Typography} from '@mui/material'

// ** Icon Imports
import Icon from "src/@core/components/icon";

export default function RequisiteItem(props) {
  let { clickHandler, requisite, code, sizeText } = props;

  let {
    enabled,
    isCrypto: isCryptoRequisite,
    balance,
    name,
    blocked,
    isFavorite,
  } = requisite;

  let textColor = "inherit";

  if (!enabled) {
    textColor = "text.secondary";
  }

  if (blocked) {
    textColor = "error";
  }
  if (isCryptoRequisite) {
    textColor = "inherit";
  }

  return (
    <ListItemButton onClick={() => clickHandler(requisite)}>
      <Box
        sx={{
          display: "flex",
          pl: 3.5,
          justifyContent: "space-between",
          width: "100%",
          py: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& svg": {
              color: "primary.dark",
            },
          }}
        >
          {isFavorite && <Icon icon="ph:star-fill" fontSize="18px" />}
          <Typography sx={{ ml: 1 }} variant={sizeText} color={textColor}>
            {name || "-"}
          </Typography>
        </Box>

        {!isCryptoRequisite && (
          <Typography
            fontWeight="semi-bold"
            color={textColor}
            variant={sizeText}
          >
            {`${balance}${code ? " " + code : ""}`}
          </Typography>
        )}
      </Box>
    </ListItemButton>
  );
}
