import { useEffect, useState } from "react";
import { Box, Typography, Grid, Button, Divider } from "@mui/material";

import ActionBtns from "./ActionBtns";
import SelectedItem from "./SelectedItem";
import Loading from "./Loading";
import SelectOtherCurrency from "./SelectOtherCurrency";

// Services
import WalletService from "src/service/Wallet";
import CurrenciesService from "src/service/Currencies";
import OrderService from "src/service/Order";

// Toast
import { toast } from "react-hot-toast";

// Modules
import WalletSelect from "src/modules/Wallets/WalletSelect";

import ChooseFavoriteWallet from "./ChooseFavoriteWallet";
// Helpers
import calcWithPrecision from "src/helpers/calcWithPrecision";

export default function ChooseWalletSend({
  currency,
  sum,
  orderId,
  handleClose,
  code,
}) {
  let [isSubmitting, setSubmitting] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [selectedWallets, setSelectedWallets] = useState([]);
  let [isOtherCurrency, setIsOtherCurrency] = useState(null);
  let [currencies, setCurrencies] = useState([]);
  let [otherCurrencyId, setOtherCurrencyId] = useState("");
  let [kits, setKits] = useState([]);

  // Handlers
  const apiSubmitHandler = () => {
    console.log(selectedWallets, "selectedWallets");
    let isWalletCommonCur = areAllElementsSame(
      selectedWallets.map((c) => c.currencyId)
    );
    // if (!isWalletCommonCur) {
    //   return toast.error(
    //     "У всех выбранных комплектов и реквизитов должна быть единая валюта!"
    //   );
    // }

    let commonCurId = selectedWallets[0].currencyId;

    let queryObj = {
      list: selectedWallets,
    };

    if (isOtherCurrency) {  
      if (otherCurrencyId !== commonCurId) {
        queryObj.otherCurrencyId = commonCurId;
      } else {
        queryObj.otherCurrencyId = otherCurrency._id;
      }
      queryObj.isOtherCurrency = true;
    } else {
      if (currency._id !== commonCurId) {
        queryObj.otherCurrencyId = commonCurId;
        queryObj.isOtherCurrency = true;
      }
    }
    setSubmitting(true);
    OrderService.confirmSend(orderId, queryObj)
      .then(() => {
        toast.success("Отправка средств клиенту подтверждена!");
        handleClose();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handlerAddWallet = (
    added,
    curId,
    initIsCrypto,
    curCode,
    curValue,
    curPrecision
  ) => {
    let exist = selectedWallets.find((i) => i._id == added._id);
    if (exist) return;

    let currencyId;
    let isCrypto;
    let code;
    let value;
    let precision = 0;
    if (curId) {
      currencyId = curId;
      isCrypto = initIsCrypto;
      code = curCode;
      value = curValue;
      precision = curPrecision;
    } else {
      if (isOtherCurrency) {
        currencyId = otherCurrencyId;
        isCrypto = otherCurrency.isCrypto;
        code = otherCurrency.code;
        value = otherCurrency.value;
        precision = otherCurrency.precision;
      } else {
        currencyId = currency._id;
        isCrypto = currency.isCrypto;
        code = currency.code;
        value = currency.value;
        precision = currency.precision;
      }
    }
    let newWallet = {
      _id: added._id,
      name: added.name,
      sum: 0,
      cms: 0,
      currencyId,
      isCrypto,
      value,
      code,
      precision,
    };
    setSelectedWallets((wallets) => [newWallet, ...wallets]);
  };

  const deleteItem = (id) =>
    setSelectedWallets((wallets) => wallets.filter((w) => w._id !== id));

  const changeItem = (upd) =>
    setSelectedWallets((wallets) =>
      wallets.map((w) => (upd._id == w._id ? upd : w))
    );

  // Calc
  let otherCurrency = currencies.find((c) => c._id == otherCurrencyId);

  let originIsCrypto = isOtherCurrency
    ? otherCurrency.isCrypto
    : currency.isCrypto;
  let curCode = isOtherCurrency ? otherCurrency.code : code;
  let isCrypto = isOtherCurrency ? otherCurrency.isCrypto : originIsCrypto;
  let fullSendSum = isOtherCurrency
    ? getConvertedSum(otherCurrency.value, otherCurrency.precision, sum)
    : sum;

  if (currencies.length && !otherCurrencyId) {
    setOtherCurrencyId(currencies[0]._id);
  }

  let isEmptyKits = !kits.length;

  // Hooks
  useEffect(() => {
    CurrenciesService.getList({}).then(({ data }) => setCurrencies(data));
  }, []);

  useEffect(() => {
    let currencyId;
    if (isOtherCurrency) {
      setSelectedWallets([]);
      if (!otherCurrencyId) return;
      currencyId = otherCurrencyId;
    } else {
      setSelectedWallets([]);
      setIsLoading(true);
      currencyId = currency._id;
    }

    WalletService.getOutcomeKitsList({ currency: currencyId }).then(
      ({ data }) => {
        setKits(data);
        setIsLoading(false);
      }
    );
  }, [isOtherCurrency, otherCurrencyId]);


  if (isLoading) return <Loading />;
  return (
    <Box
    // sx={{ px: 3, py: 2 }}
    >
      <ChooseFavoriteWallet setHandler={handlerAddWallet} />

      {isOtherCurrency ? (
        <SelectOtherCurrency
          currencies={currencies}
          currencyId={otherCurrencyId}
          setCurrencyId={setOtherCurrencyId}
          handleClose={() => {
            setIsOtherCurrency(false);
          }}
        />
      ) : (
        <Box sx={{ p: 3, pb: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                onClick={() => setIsOtherCurrency(true)}
                fullWidth
                size="small"
                variant="contained"
              >
                Сменить валюту
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      <Typography sx={{ mb: 3, px: 4, mt: 2 }} variant="body1">
        {/* {`Выберите ${isCrypto ? "комплекты" : "реквизиты"}  :`} */}
        Выберите счета:
      </Typography>

      {isEmptyKits ? (
        emptyKitsComponent
      ) : (
        <>
          <WalletSelect
            isOutcome
            isCryptoOutcome={isCrypto}
            kits={kits}
            code={curCode}
            setHandler={handlerAddWallet}
          />
        </>
      )}
      <Divider />

      <Typography sx={{ mb: 3, px: 4 }} variant="body1">
        {/* {`Выбранные ${isCrypto ? "комплекты" : "реквизиты"}  :`} */}
        Выбранные счета:
      </Typography>

      <Box
        sx={{
          px: 3,
        }}
      >
        {selectedWallets.map((w) => {
          return (
            <SelectedItem
              deleteHandler={deleteItem}
              changeHandler={changeItem}
              key={w._id}
              code={w.code}
              sum={getCalculateFullSum(
                sum,
                currency.value,
                currency.precision,
                w.value,
                w.precision
              )}
              // sum={fullSendSum}

              data={w}
              isCrypto={w.isCrypto}
            />
          );
        })}
      </Box>

      <Box sx={{ px: 3 }}>
        <ActionBtns
          showBtn={selectedWallets.length > 0}
          isSubmitting={isSubmitting}
          submitHandler={apiSubmitHandler}
        />
      </Box>
    </Box>
  );
}

const emptyKitsComponent = (
  <Typography variant="body1" textAlign="center">
    Список комплектов пуст
  </Typography>
);

function getConvertedSum(value, precision, sum) {
  let convertedSum = sum / value;
  return calcWithPrecision(convertedSum, precision);
}

function areAllElementsSame(array) {
  // Проверяем, что массив не пустой
  if (array.length === 0) {
    return false;
  }
  // Получаем первый элемент массива
  const firstElement = array[0];
  // Проверяем, что все остальные элементы равны первому
  for (let i = 1; i < array.length; i++) {
    if (array[i] !== firstElement) {
      return false;
    }
  }
  return true;
}

function getCalculateFullSum(
  originSum,
  originValue,
  originPrecision,
  convertedValue,
  convertedPrecision
) {
  let sumInRub = calcWithPrecision(originSum * originValue, originPrecision);
  return calcWithPrecision(sumInRub / convertedValue, convertedPrecision);
}
