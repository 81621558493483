import { Fragment, useEffect, useState } from "react";
// Mui
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";

import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Helpers
import sortWallet from "src/helpers/wallet/sortWallet";

function WalletSelect(props) {
  let {
    kits,
    code = "",
    setHandler = () => {},
    smallText = false,
    isOutcome = false,
    isCryptoOutcome = false,
  } = props;

  // States
  let [openKitId, setOpenKitId] = useState("");

  // Handlers
  const kitClickHandler = (kit) => {
    if (!isOutcome) {
      setOpenKitId(openKitId == kit._id ? "" : kit._id);
      return;
    }
    if (isCryptoOutcome) {
      setHandler(kit);
    } else {
      setOpenKitId(openKitId == kit._id ? "" : kit._id);
    }
  };
  const requisiteClickHandler = (req) => {
    if (!isOutcome) {
      setHandler(req);
      return;
    }
    if (!isCryptoOutcome) {
      setHandler(req);
    }
  };

  // Calc
  let sortedKits = sortWallet(kits);

  let kitSizeText = "body1";
  let requisiteSizeText = "body2";

  if (smallText) {
    kitSizeText = "body2";
    requisiteSizeText = "body2";
  }

  return (
    <List dense>
      {sortedKits.map((kit) => {
        let requisites = kit.requisites || [];
        let sortedRequisites = sortWallet(requisites);
        let hasRequisites = !!requisites.length;

        // let

        return (
          <Fragment key={kit._id}>
            <KitItem
              isOpen={kit._id == openKitId}
              sizeText={kitSizeText}
              kit={kit}
              //   isCrypto={isCrypto}
              code={code}
              kitClickHandler={kitClickHandler}
              hasRequisites={hasRequisites}
            />
            <Collapse in={openKitId === kit._id} timeout="auto" unmountOnExit>
              <List dense component="div" disablePadding>
                {sortedRequisites.map((requisite) => {
                  return (
                    <RequisiteItem
                      key={requisite._id}
                      sizeText={requisiteSizeText}
                      requisiteClickHandler={requisiteClickHandler}
                      requisite={requisite}
                      //   isCrypto={isCrypto}
                      code={code}
                    />
                  );
                })}
              </List>
            </Collapse>
          </Fragment>
        );
      })}
    </List>
  );
}

function KitItem(props) {
  let {
    kitClickHandler,
    kit,
    isCrypto,
    isOpen,
    code,
    sizeText,
    hasRequisites,
  } = props;

  // Calc
  let { enabled, name, balance, isFavorite } = kit;

  let textColor = "inherit";

  if (!enabled) {
    textColor = "text.secondary";
  }

  return (
    <ListItemButton selected={isOpen} onClick={() => kitClickHandler(kit)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          py: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& svg": {
              color: "primary.dark",
            },
          }}
        >
          {isFavorite && <Icon icon="ph:star-fill" fontSize="18px" />}

          <Typography
            sx={{ ml: 1 }}
            noWrap={false}
            variant={sizeText}
            color={textColor}
          >
            {name}
          </Typography>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Typography color={textColor} variant={sizeText}>
            {`${balance}${code ? " " + code : ""}`}
          </Typography>
          {hasRequisites && (
            <Icon icon={isOpen ? "mdi:chevron-up" : "mdi:chevron-down"} />
          )}
        </Box>
      </Box>
    </ListItemButton>
  );
}

function RequisiteItem(props) {
  let { requisiteClickHandler, requisite, isCrypto, code, sizeText } = props;

  let {
    enabled,
    isCrypto: isCryptoRequisite,
    balance,
    name,
    blocked,
    isFavorite,
  } = requisite;

  let textColor = "inherit";

  if (!enabled) {
    textColor = "text.secondary";
  }

  if (blocked) {
    textColor = "error";
  }
  if (isCryptoRequisite) {
    textColor = "inherit";
  }

  return (
    <ListItemButton onClick={() => requisiteClickHandler(requisite)}>
      <Box
        sx={{
          display: "flex",
          pl: 3.5,
          justifyContent: "space-between",
          width: "100%",
          py: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& svg": {
              color: "primary.dark",
            },
          }}
        >
          {isFavorite && <Icon icon="ph:star-fill" fontSize="18px" />}
          <Typography sx={{ml: 1}} variant={sizeText} color={textColor}>
            {name || "-"}
          </Typography>
        </Box>

        {!isCryptoRequisite && (
          <Typography
            fontWeight="semi-bold"
            color={textColor}
            variant={sizeText}
          >
            {`${balance}${code ? " " + code : ""}`}
          </Typography>
        )}
      </Box>
    </ListItemButton>
  );
}

export default WalletSelect;
