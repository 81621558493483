import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";

import { Icon } from "@iconify/react";

export default function SelectedItem(props) {
  let { isCrypto, data, deleteHandler, changeHandler, sum, code } = props;
  let name = data.name;
  return (
    <Card sx={{ mb: 2.5 }}>
      <CardContent
        sx={{
          p: 2,
          pb: "12px !important",
        }}
      >
        <Typography sx={{ mb: 2 }} variant="body2">{`${
          isCrypto ? "Комплект" : "Реквизит"
        }: ${name}`}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onClick={() =>
              changeHandler(
                Object.assign({}, data, {
                  _id: data._id,
                  sum: sum,
                })
              )
            }
            color="primary"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="mdi:tray-full" />
          </IconButton>
          <Box sx={{ flexGrow: 1, ml: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={data.sum}
                  onChange={(e) =>
                    changeHandler(
                      Object.assign({}, data, {
                        _id: data._id,
                        sum: e.target.value,
                      })
                    )
                  }
                  size="small"
                  label={`Сумма ${code || ""}`}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={data.cms}
                  onChange={(e) =>
                    changeHandler(
                      Object.assign({}, data, {
                        _id: data._id,
                        cms: e.target.value,
                      })
                    )
                  }
                  size="small"
                  label={`Комиссия ${code || ""}`}
                  type="number"
                />
              </Grid>
            </Grid>
          </Box>
          <IconButton
            onClick={() => deleteHandler(data._id)}
            color="error"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="ic:round-delete" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}
