import React, { useEffect, useState } from "react";

// Editor
import { Editor } from "@tinymce/tinymce-react";

function CustomEditor({ value, onChange }) {
  const changeContentHandler = (e) => {
    let content = e.target.getContent();
    onChange(content);
  };

  return (
    <Editor
      initialValue={value}
      apiKey='xf7tljn1dc3ibxd2os5itf28gfwg9sqoxptp5yyocuusp2o0'
      init={{
        language: "ru",
        branding: false,
        height: 500,
        menubar: true,
        plugins:
          "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
        toolbar:
          "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
        image_advtab: true,
      }}
      onChange={changeContentHandler}
    />
  );
}
export default CustomEditor;
