// Mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Ui
import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";



function Fields(props) {
  let {
    style = {},
    old = false,
    list = [],
    isIncome = false,
    hasPaymentLinks = false,
  } = props;

  let notify = null;

  if (isIncome && !list.length && !hasPaymentLinks) {
    notify = (
      <Typography sx={{ mt: 2 }} variant="body2">
        Поля и ссылки для оплаты отсутствуют. Рекомендуется отклонить платеж
      </Typography>
    );
  }

  if (!list.length) {
    notify = (
      <Typography sx={{ mt: 2 }} variant="body2">
        Поля отсутствуют
      </Typography>
    );
  }

  if(notify){
    return notify;
  }

  return (
    <Box sx={{ ...style }}>
      {list.map((f, i) => {
        return (
          <Box
            key={i}
            sx={{
              display: "flex",
              alignItems: "center",
              maxWidth: "100%",
            }}
          >
            <Typography
              sx={{ mr: 2 }}
              variant="body2"
            >
              {f.name}:
            </Typography>
            <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center",  }}>
              <Typography  variant="body1" sx={{wordBreak: "break-word"}}>
                {f.value}
              </Typography>
              {!old && <CopyClipboardWrap value={f.value.trim()} />}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default Fields;
