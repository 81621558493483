import { useState, useEffect } from "react";
//MUi
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import StoreCryptocurrencyNets from "src/store/currency/CryptocurrencyNets";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";

import { observer } from "mobx-react-lite";

// Config
import ConfigApi from "src/configs/api";

import { useTheme, useMediaQuery } from "@mui/material";

// Custom Ui
import BalanceInfo from "./Ui/BalanceInfo";
import ActiveSwitch from "./Ui/ActiveSwitch";
import CustomTextField from "./Ui/CustomTextField";
import Favorite from "./Ui/Favorite";

// Modules
import CryptoRequisite from "./CryptoRequisite";
import RequisitesWrap from "./RequisitesWrap";
import SingleAccountDialog from "../History/SingleAccountDialog";

// Custom components
import LoyaltySelect from "src/components/LoyaltySelect";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import WalletService from "src/service/Wallet";
// Hooks
import { useAuth } from "src/hooks/useAuth";

// Socket
import { socket } from "src/socket";
// Toast
import { toast } from "react-hot-toast";

// Modules
import DialogChangeBalance from "./DialogChangeBalance";

export default function CryptoKit(props) {
  let {
    data,
    currency,
    deleteItemHandler,
    style = {},
    openTransferSum,
  } = props;
  const theme = useTheme();
  const { isAdmin } = useAuth();

  // State
  let [name, setName] = useState(data.name);
  let [kit, setKit] = useState(data);
  let [showMore, setShowMore] = useState(false);
  const [showChangeBalance, setShowChangeBalance] = useState({
    show: false,
    action: "add",
    _id: "",
  });
  let [showHistory, setShowHistory] = useState(false);
  let [showHistoryData, setShowHistoryData] = useState(null);

  // Hooks
  useEffect(() => {
    socket.on(`update_kit_${data._id}`, updateKitHandler);
    return () => {
      socket.off(`update_kit_${data._id}`);
    };
  }, []);

  // Handlers
  const openShowHistory = (id) => {
    setShowHistoryData({
      id,
      mode: "kit",
    });
    setShowHistory(true);
  };
  const updateKitHandler = (upd) => {
    setKit((k) => Object.assign({}, k, upd));
  };
  const changeLoyaltyHadnler = (loyalty) => {
    WalletService.changeKit({
      _id: kit._id,
      loyalty,
    }).then(() => {
      toast.success(`Уровень лояльности изменен!`);
    });
  };
  const changeEnabledHadnler = (enabled) => {
    WalletService.changeKit({
      _id: kit._id,
      enabled,
    }).then(() => {
      toast.success(`Комплект ${enabled ? "включен" : "выключен"}!`);
    });
  };
  const saveNameHandler = (v) => {
    WalletService.changeKit({
      _id: kit._id,
      name,
    }).then(() => {
      toast.success(`Комплект изменен!`);
    });
  };

  const deleteHandler = () => {
    if (!window.confirm("Удалить комплект?")) return;

    WalletService.deleteKit(kit._id).then((r) => {
      deleteItemHandler(kit._id);
      toast.success(`Комплект удален!`);
    });
  };

  const openChangeBalance = (action, _id) => {
    setShowChangeBalance({
      show: true,
      action,
      _id,
    });
  };
  const closeChangeBalance = () => {
    setShowChangeBalance(Object.assign({}, showChangeBalance, { show: false }));
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const btns = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
          sx: "row-reverse",
          md: "row",
        },
        alignItems: "center",
      }}
    >
      <IconButton sx={{ mt: 2 }} onClick={() => setShowMore(!showMore)}>
        <Icon icon="material-symbols:expand-more-rounded" />
      </IconButton>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <IconButton
          color="primary"
          variant="contained"
          onClick={() => openShowHistory(kit._id)}
          title="История комплекта"
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
            borderRadius: 1,
            ml: 2,
          }}
          fullWidth
        >
          <Icon icon="material-symbols:history-edu-outline" />
        </IconButton>
        {kit.balance <= 0 && (
          <IconButton
            onClick={deleteHandler}
            sx={{
              ml: 2,
              borderRadius: 1,
            }}
            color="error"
          >
            <Icon icon="ic:baseline-delete-forever" />
          </IconButton>
        )}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        ...style,
      }}
    >
      <SingleAccountDialog
        data={showHistoryData}
        open={showHistory}
        closeHandler={() => {
          setShowHistory(false);
          setShowHistoryData(null);
        }}
      />
      <DialogChangeBalance
        action={showChangeBalance.action}
        show={showChangeBalance.show}
        closeHandler={closeChangeBalance}
        isKit
        _id={showChangeBalance._id}
      />
      <Card>
        <CardContent>
          <Grid spacing={4} container>
            <Grid item xs={12} md={isAdmin ? 5 : 7}>
              <Box sx={{ display: "flex" }}>
                <ActiveSwitch
                  isKit
                  number={kit.number}
                  enabled={kit.enabled}
                  onChange={changeEnabledHadnler}
                  style={{ mr: 3 }}
                />
                <CustomTextField
                  label="Название"
                  placeholder="Введите название"
                  isChanged={name.trim() !== kit.name.trim()}
                  saveHandler={saveNameHandler}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Favorite
                  _id={kit._id}
                  isKit
                  isFavorite={kit.isFavorite}
                  style={{ ml: 2 }}
                />
              </Box>
              {isDesktop && btns}
            </Grid>
            <Grid item xs={12} md={2}>
              <LoyaltySelect
                initValue={kit.loyalty}
                value={kit.loyalty}
                setValue={changeLoyaltyHadnler}
                style={{ mb: 3 }}
              />
              <Button
                fullWidth
                onClick={() =>
                  openTransferSum({ id: kit._id, isKit: true }, currency)
                }
                startIcon={<Icon icon="fa6-solid:money-bill-transfer" />}
              >
                Перевод
              </Button>
            </Grid>
            {isAdmin && (
              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => openChangeBalance("add", kit._id, true)}
                  startIcon={<Icon icon="bi:box-arrow-in-down" />}
                >
                  Внести
                </Button>
                <Button
                  sx={{ mt: 3 }}
                  fullWidth
                  variant="outlined"
                  onClick={() => openChangeBalance("sub", kit._id, true)}
                  startIcon={<Icon icon="bi:box-arrow-in-up" />}
                >
                  Вывести
                </Button>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <BalanceInfo
                balance={`${kit.balance}`}
                isGeneral={false}
                currency={currency}
                disabledBalance={kit.disabledBalance}
              />
            </Grid>
          </Grid>
          {!isDesktop && btns}
        </CardContent>
      </Card>
      <Collapse in={showMore} timeout="auto" unmountOnExit>
        <CryptoRequisites kit={kit} />
      </Collapse>
    </Box>
  );
}

function CryptoRequisites(props) {
  let { kit, currency } = props;

  const [loaded, setLoaded] = useState(false);
  const [showAddNet, setShowAddNet] = useState(false);

  const [requisites, setRequisites] = useState([]);

  //Hooks
  useEffect(() => {
    socket.on("update_item_req", (r) => {
      setRequisites((list) =>
        list.map((req) => (req._id == r._id ? Object.assign({}, req, r) : req))
      );
    });
    WalletService.getRequisiteList(kit._id).then((r) => {
      setRequisites(r.data);
      setLoaded(true);
    });

    return () => {
      socket.off(`update_item_req`);
    };
  }, []);

  // Requisites
  const addRequisiteHandler = (isNet = false) => {
    if (isNet) {
      setShowAddNet(true);
      return;
    }
    WalletService.createRequisite({
      kit: kit._id,
    }).then((r) => {
      setRequisites((list) => [r.data, ...list]);
      toast.success(`Реквизит добавлен!`);
    });
  };

  const deleteRequisiteHandler = (id) => {
    if (!window.confirm("Удалить реквезит?")) return;
    WalletService.deleteRequisite(id).then((r) => {
      setRequisites((list) => list.filter((r) => r._id !== id));
      toast.success(`Реквизит удален!`);
    });
  };

  if (!loaded)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 3,
        }}
      >
        <CircularProgress />
      </Box>
    );

  let requisitesSorted = requisites.sort((a, b) => {
    return a.number - b.number;
  });

  // fetch requisites

  return (
    <Box sx={{ ml: 4, mt: 4 }}>
      <AddNetDialog
        open={showAddNet}
        kit={kit}
        setRequisites={setRequisites}
        handleClose={() => {
          setShowAddNet(false);
        }}
      />
      <RequisitesWrap
        isCrypto={kit.isCrypto}
        requisites={requisitesSorted}
        addHandler={addRequisiteHandler}
        RequisiteComponent={CryptoRequisite}
        RequisiteComponentProps={{
          deleteHandler: deleteRequisiteHandler,
          currency: currency,
        }}
      />
    </Box>
  );
}

function AddNetDialog(props) {
  let { open, handleClose, kit, setRequisites } = props;
  // State
  const [generalAddresses, setGeneralAddresses] = useState([]);
  const [selectId, setSelectId] = useState("");

  const [isSubmiting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!open) return;
    WalletService.generalAddressGetAll().then((r) =>
      setGeneralAddresses(r.data)
    );
  }, [open]);

  const onSubmit = function () {
    if(!window.confirm('Вы уверены? Сеть должна соответствовать валюте!')) return;
    setIsSubmitting(true);
    WalletService.createRequisite({
      kit: kit._id,
      isNet: true,
      addressId: selectId,
    })
      .then((r) => {
        setRequisites((list) => [r.data, ...list]);
        toast.success(`Реквизит добавлен!`);
        handleClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Box>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            px: { xs: 8, sm: 12 },
            py: { xs: 8, sm: 12 },
            position: "relative",
          }}
        >
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
          >
            <Icon icon="mdi:close" />
          </IconButton>
          <Box sx={{ mb: 3, textAlign: "center" }}>
            <Typography variant="h6" sx={{ mb: 3, lineHeight: "2rem" }}>
              Добавить адрес сети
            </Typography>
            <SelectGeneralAddress
              value={selectId}
              setValue={setSelectId}
              list={generalAddresses}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ pb: { xs: 4, sm: 6 }, justifyContent: "center" }}>
          <Button
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting || !selectId}
            variant="contained"
            sx={{ mr: 2 }}
            type="submit"
            onClick={onSubmit}
          >
            Добавить
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const SelectGeneralAddress = observer(function (props) {
  let {
    setValue,
    value,
    containerStyle = {},
    list = [],
    size = "medium",
  } = props;
  const nets = StoreCryptocurrencyNets.getNets;

  return (
    <FormControl size={size} sx={containerStyle} fullWidth>
      <InputLabel id="currency-select">Выберите общий адрес</InputLabel>
      <Select
        size={size}
        fullWidth
        value={value}
        id="currency-select"
        label={"Выберите общий адрес"}
        labelId="currency-select"
        renderValue={(value) => {
          let ad = list.find((a) => a._id == value);
          return (
            <SelectGeneralAddressItem
              netId={ad.net}
              name={ad.name}
              address={ad.address}
              nets={nets}
            />
          );
        }}
        onChange={(e) => setValue(e.target.value)}
        inputProps={{ placeholder: "Адрес" }}
      >
        {list.map((n) => {
          return (
            <MenuItem key={n._id} value={n._id}>
              <SelectGeneralAddressItem
                netId={n.net}
                name={n.name}
                address={n.address}
                nets={nets}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
});

const SelectGeneralAddressItem = observer(function (props) {
  let { name, address, netId, nets = [] } = props;

  const net = nets.find((n) => n._id == netId);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        py: 0,
      }}
    >
      <Typography variant="body1">{name}</Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Avatar
          variant="square"
          src={ConfigApi.PUBLIC_IMAGES + net?.logo + ".png"}
          alt={net?.name}
          sx={{ height: 23, width: 23, mr: 3 }}
        />
        {net?.name}
      </Box>
    </Box>
  );
});
