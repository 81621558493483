import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  List,
  Collapse,
  Divider,
} from "@mui/material";

// Services
import WalletService from "src/service/Wallet";

// Modules
import KitItem from "src/modules/SelectWallet/KitItem";
import RequisiteItem from "src/modules/SelectWallet/RequisiteItem";

// Helpers
import sortWallet from "src/helpers/wallet/sortWallet";

export default function FavoritesKits({ setHandler }) {
  let [list, setList] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  let [openKitId, setOpenKitId] = useState("");

  useEffect(() => {
    WalletService.getOutcomeKitsList({ currency: "favorite" }).then(
      ({ data }) => {
        setList(data);
        setIsLoading(false);
      }
    );
  }, []);

  // Handlers
  const kitClickHandler = (kit, curId, isCrypto, code, value, precision) => {
    // console.log(precision, 'precision')
    let isCryptoKit = kit.currency.isCrypto;
    if (isCryptoKit) {
      setHandler(kit, curId, isCrypto, code, value, precision);
    } else {
      setOpenKitId(openKitId == kit._id ? "" : kit._id);
    }
  };
  const requisiteClickHandler = (req, curId, isCrypto, code, value, precision) => {
    // console.log(precision, 'precision')

    setHandler(req, curId, isCrypto, code, value, precision);
  };

  if (isLoading) return loadingComponent;

  let kitSizeText = "body1";
  let requisiteSizeText = "body2";

  let sortedKits = sortWallet(list);

  return (
    <Box>
      <Typography sx={{ mb: 0, p: 3, px: 4, pb: 1 }} variant="body1">
        Избранное:
      </Typography>
      <List dense>
        {sortedKits.map((kit) => {
          let requisites = kit.requisites || [];
          let sortedRequisites = sortWallet(requisites);
          let hasRequisites = !!requisites.length;
          let currency = kit.currency;

          return (
            <Fragment key={kit._id}>
              <KitItem
                isOpen={kit._id == openKitId}
                sizeText={kitSizeText}
                currency={currency}
                kit={kit}
                hideFavorite
                code={currency.code}
                clickHandler={() => {
                  kitClickHandler(
                    kit,
                    currency._id,
                    currency.isCrypto,
                    currency.code,
                    currency.value,
                    currency.precision
                  );
                }}
                hasRequisites={hasRequisites}
              />
              <Collapse in={openKitId === kit._id} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                  {sortedRequisites.map((requisite) => {
                    return (
                      <RequisiteItem
                        key={requisite._id}
                        sizeText={requisiteSizeText}
                        clickHandler={() => {
                          requisiteClickHandler(
                            requisite,
                            currency._id,
                            currency.isCrypto,
                            currency.code,
                            currency.value,
                            currency.precision
                          );
                        }}
                        requisite={requisite}
                        code={currency.code}
                      />
                    );
                  })}
                </List>
              </Collapse>
            </Fragment>
          );
        })}
      </List>
      <Divider />
    </Box>
  );
}

const loadingComponent = (
  <Box
    sx={{
      mt: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </Box>
);
