import { useEffect, useState } from "react";

// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// Custom
import PageWrap from "src/components/PageWrap";
import CurrencySelect from "src/components/CurrencySelect";

// ** Icon
import Icon from "src/@core/components/icon";

//ws
// Modules
import AddKitModal from "./modules/AddKitModal";
import CryptoKit from "./modules/CryptoKit";
import FiatKit from "./modules/FiatKit";
import DialogTransferBalance from "./modules/DialogTransferBalance";
import KitsWrap from "./modules/KitsWrap";
import GeneralSelect from "./modules/GeneralSelect";
import LinksToConnect from "./modules/LinksToConnect";

import ChangeBalanceModal from "./modules/ChangeBalanceModal";

// Services
import CurrenciesService from "src/service/Currencies";
import WalletService from "src/service/Wallet";

// Helpers
import combineCurrencyNets from "src/helpers/combineCurrencyNets";

// Methods
import sortKits from "./methods/sortKits";
//Utils
import sortCurrencies from "src/utils/currency/sortCurrencies";

// Socket
import { socket } from "src/socket";

function Control() {
  // States
  let [showAddKit, setShowAddKit] = useState(false);
  let [currencies, setСurrencies] = useState([]);
  let [currencyId, setCurrencyId] = useState("");
  let [showTransferSum, setShowTransferSum] = useState(false);
  let [transferSumData, setTransferSumData] = useState({
    id: undefined,
    isKit: false,
  });
  let [transferCurrency, setTransferCurrency] = useState(null);

  let [kits, setKits] = useState([]);

  // Hooks
  useEffect(() => {
    CurrenciesService.getList({
      populate: ["wallet"],
    }).then((r) => setСurrencies(r.data));

    socket.on("update_item_kit", (k) => {
      // console.log({ k }, "upd l");
      setKits((list) =>
        list.map((kit) => (kit._id == k._id ? Object.assign({}, kit, k) : kit))
      );
    });

    socket.on(`update_wallet_item`, (upd) => {
      setСurrencies((list) => {
        return list.map((cur) =>
          cur._id + "" == upd.currency + ""
            ? Object.assign({}, cur, { wallet: upd })
            : cur
        );
      });
    });

    return () => {
      socket.off(`update_item_kit`);
      socket.off(`update_wallet_item`);
    };
  }, []);

  useEffect(() => {
    if (!currencyId) return;
    WalletService.getKits({
      currency: currencyId,
    }).then((r) => {
      setKits(r.data);
    });
  }, [currencyId]);

  // Handlers
  const toggleShowAddKit = () => setShowAddKit(!showAddKit);

  const addItemHandler = (kit) => {
    setKits((list) => [kit, ...list]);
  };
  const deleteItemHandler = (id) => {
    setKits((list) => list.filter((k) => k._id !== id));
  };

  // Transfer Balance Handler
  const openTransferSum = (upd, currency) => {
    setTransferSumData(upd);
    setShowTransferSum(true);
    setTransferCurrency(currency);
  };
  const closeTransferSum = () => {
    setTransferSumData({ id: undefined, isKit: false });
    setShowTransferSum(false);
    setTransferCurrency(null);
  };

  // Calc
  let currency = currencies.find((c) => c._id === currencyId);

  let specific = currency?.specific;

  let isCrypto = currency?.isCrypto;

  let sortedKits = sortKits(kits, currency?.wallet?._id);

  return (
    <PageWrap title="Комплекты">
      <AddKitModal
        title="Добавить комплект"
        open={showAddKit}
        currency={currency}
        addItemHandler={addItemHandler}
        handleClose={toggleShowAddKit}
        currencies={currencies}
      />
      <DialogTransferBalance
        transferCurrency={transferCurrency}
        takeIsKit={transferSumData.isKit}
        takeId={transferSumData.id}
        show={showTransferSum}
        closeHandler={closeTransferSum}
      />

      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <CurrencySelect
                label="Валюта"
                placeholder="Выбирете валюту"
                currencies={combineCurrencyNets(sortCurrencies(currencies))}
                value={currencyId}
                setValue={(id) => {
                  setKits([]);
                  setCurrencyId(id);
                }}
                showBalance
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                startIcon={<Icon icon="material-symbols:add" />}
                fullWidth
                variant="contained"
                sx={{ height: "100%" }}
                size="large"
                onClick={toggleShowAddKit}
              >
                Добавить
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {!isCrypto && (
        <GeneralSelect
          addItemHandler={addItemHandler}
          deleteItemHandler={deleteItemHandler}
          currency={currency}
        />
      )}
      {(specific == "advc" || specific == "payeer") && (
        <LinksToConnect specific={specific} />
      )}

      <Box sx={{ mt: 6 }}>
        {isCrypto ? (
          <CryptoList
            currency={currency}
            deleteItemHandler={deleteItemHandler}
            openTransferSum={openTransferSum}
            kits={sortedKits}
            currencies={currencies}
          />
        ) : (
          <FiatList
            specific={specific}
            openTransferSum={openTransferSum}
            currency={currency}
            kits={sortedKits}
          />
        )}
      </Box>
    </PageWrap>
  );
}

function CryptoList(props) {
  let { kits, currency, deleteItemHandler, openTransferSum, currencies } =
    props;

  return (
    <Box>
      <KitsWrap
        KitComponent={CryptoKit}
        KitComponentProps={{
          openTransferSum,
          deleteItemHandler,
          currency: currency,
        }}
        kits={kits}
        wallet={currency.wallet}
        currency={currency}
        style={{ mt: 3 }}
      />
    </Box>
  );
}

function FiatList(props) {
  let { kits, currency, openTransferSum, specific } = props;

  if (!currency) return null;

  return (
    <KitsWrap
      KitComponent={FiatKit}
      KitComponentProps={{ openTransferSum, currency, specific }}
      currency={currency}
      kits={kits}
      wallet={currency.wallet}
      style={{ mt: 3 }}
    />
  );
}

export default Control;

// function sortedNetKits(currencies, currency, kits) {
//   let nets = currencies
//     .filter((c) => c.isNet && c.code === currency.code)
//     .map((n) =>
//       Object.assign(
//         {},
//         {
//           _id: n._id,
//           netCode: n.netCode,
//           wallet: n.wallet,
//           currency: n,
//           currencyCode: n.currency,
//         }
//       )
//     );

//   nets = nets.map((n) => {
//     let netKits = kits.filter((k) => k.wallet._id == n.wallet._id);
//     return Object.assign({}, n, { kits: netKits });
//   });

//   return nets;
// }

// function NetKitsWrap(props) {
//   let { style = {} } = props;
//   let [showKits, setShowKits] = useState(false);
//   return (
//     <Box sx={{ ...style }}>
//       <Card>
//         <CardContent
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           <Box sx={{ display: "flex", alignItems: "center" }}>
//             <Typography variant="body1">{props.name}</Typography>
//             <Typography sx={{ ml: 2 }} variant="body2">
//               ({`${props.balance} ${props.code}`})
//             </Typography>
//           </Box>
//           <IconButton onClick={() => setShowKits(!showKits)}>
//             <Icon icon="material-symbols:expand-more-rounded" />
//           </IconButton>
//         </CardContent>
//       </Card>
//       <Collapse in={showKits} timeout="auto" unmountOnExit>
//         {props.children}
//       </Collapse>
//     </Box>
//   );
// }
