const SERVER =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.REACT_APP_SERVER_API;

// const MODE = process.env.MODE;

// let SERVER = "";

// switch (MODE) {
//   case "TEST":
//     SERVER = "https://testapi.ex-bank.cc";
//     break;
//   case "PROD":
//     SERVER = "https://api.ex-bank.cc";
//     break;
//   default:
//     SERVER = "http://localhost:3000";
// }

const config = {
  API: `${SERVER}/crm`,
  SERVER,
  PUBLIC_IMAGES: `${SERVER}/images/`,
};

export default config;
