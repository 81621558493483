import * as React from "react";

import { Box, IconButton, Typography, Switch } from "@mui/material";

import { Icon } from "@iconify/react";

// Services
import WalletService from "src/service/Wallet";

// Modules
import CurrencyIconList from "./CurrencyIconList";
import ChangeListDalog from "./ChangeListDalog";

import { toast } from "react-hot-toast";

function KitDirections({ kitId, directions, enabled, style = {} }) {
  let [showDialog, setShowDialog] = React.useState(false);

  const toggleEnabledDirections = () => {
    let newEnabled = !enabled;

    WalletService.changeKit({
      _id: kitId,
      directionsEnabled: newEnabled,
    }).then(() => {
      toast.success(
        `Направление выдачи ${newEnabled ? "ВКЛЮЧЕНО" : "ВЫКЛЮЧЕНО"}`
      );
    });
  };

  let hasDirections = Boolean(directions.length);

  return (
    <Box
      sx={{
        ...style,
      }}
    >
      <ChangeListDalog
        directions={directions}
        open={showDialog}
        kitId={kitId}
        handleClose={() => setShowDialog(false)}
      />
      {hasDirections ? (
        <>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={enabled}
              size="small"
              sx={{ mr: 1 }}
              onChange={toggleEnabledDirections}
            />
            <Box>
              <Typography variant="body2">Направление выдачи:</Typography>
              <CurrencyIconList directions={directions} />
            </Box>
            <IconButton
              sx={{ ml: 1, p: 1 }}
              onClick={() => setShowDialog(true)}
            >
              <Icon icon="material-symbols:box-edit-outline" />
            </IconButton>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2">Направление выдачи:</Typography>
            <IconButton
              sx={{ ml: 1, p: 1 }}
              onClick={() => setShowDialog(true)}
            >
              <Icon icon="material-symbols:box-edit-outline" />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
}

export default KitDirections;
