import { useState, useEffect } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Switch from "@mui/material/Switch";
import Collapse from "@mui/material/Collapse";
import InputAdornment from "@mui/material/InputAdornment";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import { Grid, IconButton, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Сервисы
import TechnicalBreakService from "src/service/TechnicalBreak";

// Config
import WEBSITES from "src/configs/websites";

// Socket
import { socket } from "src/socket";

// Toast
import { toast } from "react-hot-toast";

function TechnicalBreak() {
  let [list, setList] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    TechnicalBreakService.getAll().then((r) => setList(r.data));
  }, []);

  return (
    <Card>
      <CardHeader
        title="Технический перерыв"
        titleTypographyProps={{
          sx: {
            lineHeight: "2rem !important",
            letterSpacing: "0.15px !important",
          },
        }}
        action={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="secondary" variant="body1" sx={{ mr: 2 }}>
              Для всех
            </Typography>
            <IconButton>
              <Icon
                fontSize={20}
                icon={!showAll ? "mdi:chevron-down" : "mdi:chevron-up"}
                onClick={() => setShowAll(!showAll)}
              />
            </IconButton>
          </Box>
        }
      />
      <Collapse in={showAll} timeout="auto" unmountOnExit>
        <TechnicalBreakAll />
      </Collapse>
      <List>
        {list.map((t) => {
          let sc = t.sc;
          let site = WEBSITES.find((s) => s.id == sc);

          if(!site) return;
          return <TechnicalBreakItem key={t._id} name={site.name} data={t} />;
        })}
      </List>
    </Card>
  );
}
function TechnicalBreakAll() {
  // States
  const [count, setCount] = useState(0);
  let [interval, setInterval] = useState("min");

  // Handlers
  const changeIntervalHandler = () => {
    setInterval(interval == "min" ? "hour" : "min");
  };

  const enabledAllHandler = () => {
    if (count <= 0) return;
    TechnicalBreakService.changeAll({
      enabled: true,
      shift: interval == "min" ? count : count * 60,
    }).then((r) => {
      toast.success("Тех. перерыв включен!");
    });
  };

  const disabledAllHandler = () => {
    TechnicalBreakService.changeAll({
      enabled: false,
    }).then(() => {
      toast.success("Тех. перерыв отключен!");
    });
  };

  return (
    <CardContent>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <TextField
            value={count}
            onChange={(e) => setCount(e.target.value)}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      borderRadius: 0,
                    }}
                    edge="end"
                    onClick={changeIntervalHandler}
                  >
                    <Typography variant="body2">
                      {interval == "min" ? " Мин." : "Час"}
                    </Typography>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            onClick={enabledAllHandler}
            disabled={count <= 0}
            fullWidth
            variant="contained"
          >
            Включить
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            onClick={disabledAllHandler}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отключить
          </Button>
        </Grid>
      </Grid>
    </CardContent>
  );
}

function TechnicalBreakItem({ name, data }) {
  let [interval, setInterval] = useState("min");
  let [tech, setTech] = useState(data);
  let [count, setCount] = useState(0);

  useEffect(() => {
    socket.on(`tb_upd_${tech._id}`, (tb) => {
      setTech(tb);
    });
    return () => {
      socket.off(`tb_upd_${tech._id}`);
    };
  }, []);

  const changeIntervalHandler = () => {
    setInterval(interval == "min" ? "hour" : "min");
  };

  function changeEnabledHandler(enabled) {
    if (enabled) {
      if (count <= 0) return;
      TechnicalBreakService.change(data._id, {
        enabled,
        shift: interval == "min" ? count : count * 60,
      }).then((r) => {
        toast.success("Тех. перерыв включен!");
      });
    } else {
      TechnicalBreakService.change(data._id, {
        enabled: false,
      }).then((r) => {
        toast.success("Тех. перерыв выключен!");
      });
    }
  }

  let timeSection = (
    <TextField
      value={count}
      onChange={(e) => setCount(e.target.value)}
      type="number"
      sx={{ mr: 3, maxWidth: "150px" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{
                borderRadius: 0,
              }}
              edge="end"
              onClick={changeIntervalHandler}
            >
              <Typography variant="body2">
                {interval == "min" ? " Мин." : "Час"}
              </Typography>
            </IconButton>
          </InputAdornment>
        ),
      }}
      size="small"
    />
  );

  if (tech.enabled) {
    timeSection = <TimeToFinish finish={tech.finish} />;
  }

  return (
    <ListItem sx={{ mb: 3 }}>
      <Typography variant="body1" fontWeight="bold">
        {name}
      </Typography>
      <ListItemSecondaryAction>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {timeSection}
          <Switch
            checked={tech.enabled}
            onChange={(e) => changeEnabledHandler(e.target.checked)}
          />
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function TimeToFinish({ finish }) {
  const [hours, minutes, seconds] = useCountdown(finish);

  function formatTime(hour, min, sec) {
    let hours = hour;
    let minutes = min;
    let seconds = sec;

    return `${hours ? `${hours} час.` : ""} ${
      minutes ? `${minutes} мин.` : ""
    } ${seconds ? `${seconds} сек.` : ""}`;
  }

  let time =
    minutes + seconds + hours <= 0
      ? "Включаемся..."
      : formatTime(hours, minutes, seconds);

  return (
    <Typography variant="body1" color="secondary" sx={{ mr: 3 }}>
      {time}
    </Typography>
  );
}

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
};

export default TechnicalBreak;
