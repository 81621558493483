//React
import { useState, useEffect } from "react";

// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import Icon from "src/@core/components/icon";

import useBgColor from "src/@core/hooks/useBgColor";

// Modules
import Acquiring from "./Acquiring/Acquiring";

// Custom Ui
import ActiveSwitch from "./Ui/ActiveSwitch";
import CustomTextField from "./Ui/CustomTextField";
import CountTextField from "./Ui/CountTextField";
import Favorite from "./Ui/Favorite";

// Services
import WalletService from "src/service/Wallet";

// Modules
import SingleAccountDialog from "../History/SingleAccountDialog";

// Socket
import { socket } from "src/socket";

// Hooks
import { useAuth } from "src/hooks/useAuth";

// Toast
import { toast } from "react-hot-toast";

// Libs
import { FormatMoney } from "format-money-js";

const fm = new FormatMoney({
  separator: " ",
  leadZeros: true,
  append: true,
});

let fields = [
  {
    name: "Номер карты",
    value: "4890494797810520",
  },
  {
    name: "Имя держателя",
    value: "Лионов Максим",
  },
];

function getFieldsObj(fields) {
  let result = {};
  fields.forEach((f) => {
    result[f._id] = f.value || "";
  });
  return result;
}

function getChangedFields(newObj, savedObj) {
  const result = { changed: false, fields: {} };

  // Сравниваем свойства объектов

  for (let prop in newObj) {
    if (newObj[prop] !== savedObj[prop]) {
      result.changed = true;
    }
    result.fields[prop] = newObj[prop];
  }

  return result;
}

function getCountObj(obj) {
  return {
    dayMaxAmount: obj.dayMaxAmount,
    dayMaxSum: obj.dayMaxSum,
    monthMaxAmount: obj.monthMaxAmount,
    monthMaxSum: obj.monthMaxSum,
    maxBalance: obj.maxBalance,
  };
}

function getChangedCounts(obj, savedObj) {
  const result = { changed: false, fields: {} };

  // Сравниваем свойства объектов
  const objProps = Object.getOwnPropertyNames(obj);

  for (let prop of objProps) {
    if (+obj[prop] !== +savedObj[prop]) {
      result.changed = true;
      result.fields[prop] = obj[prop];
    }
  }

  return result;
}

function FiatRequisite(props) {
  let {
    data,
    currency,
    deleteHandler,
    addToBalanceHandler,
    subToBalanceHandler,
    openTransferSum,
    specific = "",
  } = props;
  let [requisite, setRequisite] = useState(data);
  let [name, setName] = useState(data.name);
  let [counts, setCounts] = useState(getCountObj(data));
  let [fields, setFields] = useState(data.fields);
  let [showHistory, setShowHistory] = useState(false);
  let [showHistoryData, setShowHistoryData] = useState(null);

  // Calc
  let { changed: isChangedCounts, fields: changeCounts } = getChangedCounts(
    counts,
    getCountObj(requisite)
  );
  let { changed: isChangedFields, fields: changeFields } = getChangedFields(
    getFieldsObj(fields),
    getFieldsObj(requisite.fields)
  );

  let isEmptyBalance = requisite.balance <= 0;
  let hasFields = requisite.fields.length > 0;
  let blocked = requisite.blocked;
  let onPause = requisite.onPause;

  // Hooks
  useEffect(() => {
    socket.on(`update_req_${data._id}`, updateReqHandler);
    return () => {
      socket.off(`update_req_${data._id}`);
    };
  }, []);
  const { isAdmin } = useAuth();

  // Handlers
  const openShowHistory = (id) => {
    setShowHistoryData({
      id,
      mode: "req",
    });
    setShowHistory(true);
  };

  const changeItemField = (id, value) => {
    setFields((fields) =>
      fields.map((f) => (f._id !== id ? f : Object.assign({}, f, { value })))
    );
  };

  const changeCountHandler = (name, value) => {
    setCounts(Object.assign({}, counts, { [name]: value }));
  };

  const updateReqHandler = (upd) => {
    setRequisite((r) => Object.assign({}, r, upd));
    if (upd.fields) {
      setFields(upd.fields);
    }
  };

  const changeEnabledHadnler = (enabled) => {
    WalletService.changeRequisite({
      _id: requisite._id,
      enabled,
    }).then(() => {
      toast.success(`Реквизит ${enabled ? "включен" : "выключен"}!`);
    });
  };

  const changeBlockedHadnler = (blocked) => {
    if (
      !window.confirm(
        `${blocked ? "Заблокировать" : "Разблокировать"} реквизит?`
      )
    )
      return;

    WalletService.changeRequisite({
      _id: requisite._id,
      blocked,
    }).then(() => {
      toast.success(`Реквизит ${blocked ? "заблокирован" : "разблокирован"}!`);
    });
  };

  const changePauseHandler = (onPause) => {
    let confirmMessage = onPause
      ? "Поставить реквизит на паузу?"
      : "Запустить реквизит в оборот?";

    if (!window.confirm(confirmMessage)) {
      return;
    }

    WalletService.changeRequisite({
      _id: requisite._id,
      onPause,
    }).then(() => {
      toast.success(
        `Реквизит ${onPause ? "поставлен на паузу" : "запущен в оборот"}!`
      );
    });
  };

  const saveNameHandler = (v) => {
    WalletService.changeRequisite({
      _id: requisite._id,
      name,
    }).then(() => {
      toast.success(`Реквизит изменен!`);
    });
  };

  const saveHandler = () => {
    let fieldList = requisite.fields.map((f) => {
      return Object.assign({}, f, { value: changeFields[f._id] || "" });
    });
    WalletService.changeRequisite({
      _id: requisite._id,
      ...changeCounts,
      fields: fieldList,
    }).then(() => {
      toast.success(`Реквизит изменен!`);
    });
  };

  let controlRequisite = (
    <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        {isEmptyBalance && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              sx={{ p: 1.5, minWidth: 38, ml: 3 }}
              color={"error"}
              onClick={() => deleteHandler(data._id)}
            >
              <Icon icon="ic:round-delete-forever" />
            </Button>
          </Box>
        )}
      </Box>
      <Box>
        {(isChangedCounts || isChangedFields) && (
          <Button
            variant="outlined"
            sx={{ p: 1.5, minWidth: 38 }}
            color="primary"
            onClick={saveHandler}
          >
            <Icon icon="material-symbols:save-outline-rounded" />
          </Button>
        )}
      </Box>
    </CardContent>
  );

  let addedStyle = {};

  if (blocked) {
    addedStyle = {
      opacity: 0.6,
    };
  }

  return (
    <Card sx={{ mb: 2, ...addedStyle }} id={`${requisite._id}`}>
      <CardContent>
        <SingleAccountDialog
          data={showHistoryData}
          open={showHistory}
          closeHandler={() => {
            setShowHistory(false);
            setShowHistoryData(null);
          }}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Box sx={{ display: "flex" }}>
              <ActiveSwitch
                number={requisite.number}
                enabled={requisite.enabled}
                onChange={changeEnabledHadnler}
                style={{ mr: 2 }}
              />
              <CustomTextField
                label="Название"
                placeholder="Введите название"
                isChanged={name.trim() !== requisite.name.trim()}
                saveHandler={saveNameHandler}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Favorite
                _id={requisite._id}
                isFavorite={requisite.isFavorite}
                style={{ ml: 2 }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <RequisiteBalance
              sum={requisite.balance}
              code={currency.code}
              precision={currency.precision}
            />
          </Grid>
        </Grid>
        <Grid
          justifyContent="flex-end"
          sx={{ pt: 3 }}
          container
          columns={12}
          spacing={4}
        >
          <Grid item xs={6} md={isAdmin ? 4.5 : 9.5}>
            <IconButton
              color={blocked ? "primary" : "secondary"}
              variant="contained"
              onClick={() => changeBlockedHadnler(!blocked)}
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                borderRadius: 1,
                color: blocked ? "error.dark" : "secondary.light",
              }}
              fullWidth
            >
              <Icon
                icon={
                  blocked
                    ? "material-symbols:lock-outline"
                    : "material-symbols:lock-open-outline"
                }
              />
            </IconButton>

            <IconButton
              color={onPause ? "error" : "primary"}
              variant="contained"
              onClick={() => changePauseHandler(!onPause)}
              title={onPause ? "На паузе" : "В обороте"}
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                borderRadius: 1,
                color: onPause ? "error.dark" : "primary.light",
                ml: 3,
              }}
              fullWidth
            >
              <Icon icon={onPause ? "bi:pause-fill" : "bi:play-fill"} />
            </IconButton>

            <IconButton
              color="primary"
              variant="contained"
              onClick={() => openShowHistory(requisite._id)}
              title="История реквизита"
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                borderRadius: 1,
                ml: 3,
              }}
              fullWidth
            >
              <Icon icon="material-symbols:history-edu-outline" />
            </IconButton>
          </Grid>
          <Grid item xs={6} md={2.5}>
            <Button
              fullWidth
              onClick={() => openTransferSum(data._id)}
              startIcon={<Icon icon="fa6-solid:money-bill-transfer" />}
            >
              Перевод
            </Button>
          </Grid>
          {isAdmin && (
            <>
              <Grid item xs={6} md={2.5}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => addToBalanceHandler(data._id)}
                  startIcon={<Icon icon="bi:box-arrow-in-down" />}
                >
                  Внести
                </Button>
              </Grid>
              <Grid item xs={6} md={2.5}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => subToBalanceHandler(data._id)}
                  startIcon={<Icon icon="bi:box-arrow-in-up" />}
                >
                  Вывести
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <Divider sx={{ my: "0 !important" }} />
      <CardContent>
        <Grid container columns={15} spacing={4}>
          <Grid item xs={7.5} md={2}>
            <CountTextField
              label="Кол-во в день"
              name=""
              count={requisite.dayAmount}
              value={counts.dayMaxAmount}
              setValue={(v) => changeCountHandler("dayMaxAmount", v)}
            />
          </Grid>
          <Grid item xs={7.5} md={4}>
            <CountTextField
              label="Сумма в день"
              name=""
              count={requisite.daySum}
              value={counts.dayMaxSum}
              setValue={(v) => changeCountHandler("dayMaxSum", v)}
            />
          </Grid>
          <Grid item xs={7.5} md={2}>
            <CountTextField
              label="Кол-во в месяц"
              name=""
              count={requisite.monthAmount}
              value={counts.monthMaxAmount}
              setValue={(v) => changeCountHandler("monthMaxAmount", v)}
            />
          </Grid>

          <Grid item xs={7.5} md={4}>
            <CountTextField
              label="Сумма в месяц"
              name=""
              count={requisite.monthSum}
              value={counts.monthMaxSum}
              setValue={(v) => changeCountHandler("monthMaxSum", v)}
            />
          </Grid>
          <Grid item xs={7.5} md={3}>
            <TextField
              size="small"
              name=""
              label="Макс. баланс"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  paddingLeft: 0,
                },
              }}
              // required
              fullWidth
              value={counts.maxBalance}
              onChange={(e) => changeCountHandler("maxBalance", e.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        {hasFields ? (
          <Grid container spacing={4}>
            {fields.map((f) => {
              return (
                <Grid key={f._id} item xs={12} md={6}>
                  <CustomTextField
                    label={f.name}
                    value={f.value}
                    onChange={(e) => changeItemField(f._id, e.target.value)}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1" textAlign="center">
            Нет полей
          </Typography>
        )}
      </CardContent>

      {isChangedCounts || isChangedFields || isEmptyBalance
        ? controlRequisite
        : null}

      {(specific == "payeer" || specific == "advc" || specific == "ym") && (
        <CardContent>
          <Acquiring requisiteId={requisite._id} specific={specific} />
        </CardContent>
      )}
    </Card>
  );
}

function RequisiteBalance({ sum, code }) {
  const bgColors = useBgColor();

  return (
    <Box
      sx={{
        paddingX: 1,
        paddingY: 2.2,
        width: "100%",
        height: "100%",
        borderRadius: 1,
        color: "text.primary",
        backgroundColor: bgColors.primaryLight.backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="body2">{sum}</Typography>
      <Typography component="sub" variant="body2" sx={{ ml: 1 }}>
        {code}
      </Typography>
    </Box>
  );
}

export default FiatRequisite;
