const SERVICES = [
  {
    name: "Telegram (Ex-bank)",
    code: "telegram",
    short: "telegram",
    id: "t",
    color: "#3bb4d2",
  },
];

export default SERVICES;
