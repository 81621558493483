import React, { useState, useEffect } from "react";

// Mui
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";


// Services

// Config
import ConfigApi from "src/configs/api";

function CurrencyNetSelect(props) {
  let {
    label,
    placeholder,
    setValue,
    value,
    containerStyle = {},
    nets = [],
    size = "medium",
  } = props;

  return (
    <FormControl size={size} sx={containerStyle} fullWidth>
      <InputLabel id="currency-select">{label}</InputLabel>
      <Select
        size={size}
        fullWidth
        value={value}
        id="currency-select"
        label={label}
        labelId="currency-select"
        renderValue={(value) => {
          let n = nets.find((c) => c._id == value);
          return <SelectItem name={n.name} logo={n.logo} />;
        }}
        onChange={(e) => setValue(e.target.value)}
        inputProps={{ placeholder: placeholder }}
      >
        {nets.map((n) => {
          return (
            <MenuItem key={n._id} value={n._id}>
              <SelectItem name={n.name} logo={n.logo} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default CurrencyNetSelect;

function SelectItem(props) {
  let { name, logo } = props;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        py: 0,
      }}
    >
      <Box sx={{ display: "flex" }}>
        {logo && (
          <Avatar
            variant="square"
            src={ConfigApi.PUBLIC_IMAGES + logo + ".png"}
            alt={name}
            sx={{ height: 23, width: 23, mr: 3 }}
          />
        )}
        {name}
      </Box>
    </Box>
  );
}
