// Services
import InfoService from "src/service/Info";
import CurrencyService from "src/service/Currencies";

// Store
import StoreServiceData from "src/store/ServiceData";
import CryptocurrencyNetsStore from "src/store/currency/CryptocurrencyNets";

// promise
async function initLoadData() {
  let fiatPrice = InfoService.getFiatPrice();
  let cryptoCurrenciesNets = CurrencyService.cryptoCurrencyNetsGetAll();

  return new Promise((res, rej) => {
    Promise.all([fiatPrice, cryptoCurrenciesNets]).then((values) => {
      StoreServiceData.setFiatPrices(values[0].data);
      CryptocurrencyNetsStore.setNetsList(values[1].data)
      res();
    });
  });
}

export default initLoadData;
