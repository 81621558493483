import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// Components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// Configs
import WEBSITESANDSERVICES from "src/configs/websitesAndServices";
import { useTheme, useMediaQuery } from "@mui/material";
// ** Icon
import Icon from "src/@core/components/icon";
import CurrencyCard from "./CurrencyCard";

// Draggable
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Socket
import { socket } from "src/socket";

// Toast
import { toast } from "react-hot-toast";

// Services
import SiteCurrencyService from "src/service/SiteCurrency";

const Currencies = () => {
  const [currencies, setCurrencies] = useState([]);
  const [sortCurrencies, setSortCurrencies] = useState([]);
  let [search, setSearch] = useState("");
  let [isSorting, setIsSorting] = useState(false);
  const theme = useTheme();
  const params = useParams();
  const site = WEBSITESANDSERVICES.find((s) => s.id === params.site);


  // console.log(site, 'site')
  // Hooks
  useEffect(() => {
    SiteCurrencyService.getSiteCurrencyList({ sc: site.id, enbl: true }).then(
      (r) => {
        setCurrencies(r.data);
      }
    );
    socket.on("site_currency_add", (c) =>
      setCurrencies((list) => [c, ...list])
    );
    //
    return () => {
      socket.off("site_currency_add");
    };
  }, [site.id]);

  // API
  const saveSortHandler = () => {
    let currenciesList = sortCurrencies.map((c, i) => {
      return Object.assign({}, c, { srt: i + 1 });
    });
    SiteCurrencyService.sortSiteCurrencyList({
      sort: currenciesList.map((c) => ({ _id: c._id, srt: c.srt })),
    }).then(() => {
      toast.success("Валюты отсортированны!");
      setCurrencies(currenciesList);
      setIsSorting(false);
    });
  };

  // Drag and drop

  const startSortingHandler = () => {
    setSortCurrencies(currencies);
    setIsSorting(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const list = reorder(
      sortCurrencies,
      result.source.index,
      result.destination.index
    );

    setSortCurrencies(list);
  };

  // Calcl

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let filteredCurrencies = search
    ? currencies.filter(
        (c) =>
          c.cur.name.toLowerCase().includes(search.toLowerCase()) ||
          c.cur.code.toLowerCase().includes(search.toLowerCase())
      )
    : currencies;

  filteredCurrencies.sort((a, b) => a.srt - b.srt);

  // Component calc

  let currencyListComponent = (
    <Grid sx={{ mt: 6 }} container spacing={4}>
      {filteredCurrencies.map((c) => (
        <Grid key={c._id} item xs={12} md={6} lg={3}>
          <CurrencyCard d={c} />
        </Grid>
      ))}
    </Grid>
  );

  if (isSorting) {
    currencyListComponent = (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <Grid
              sx={{ mt: 6 }}
              container
              spacing={4}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sortCurrencies.map((c, index) => (
                <Draggable key={c._id} draggableId={c._id} index={index}>
                  {(provided, snapshot) => (
                    <Grid
                      key={c._id}
                      item
                      xs={12}
                      md={6}
                      lg={3}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <CurrencyCard d={c} />
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return (
    <Box>
      <Typography sx={{ mb: 4 }} variant="h5">
        Валюты ({site.name})
      </Typography>

      <Grid container spacing={4}>
        {isDesktop && <Grid item md={6}></Grid>}
        <Grid xs={0} md={2}></Grid>
        <Grid item xs={12} md={2}>
          {isSorting ? (
            <Button
              fullWidth
              variant="contained"
              size="medium"
              onClick={saveSortHandler}
            >
              Сохранить
            </Button>
          ) : (
            <TextField
              size="small"
              fullWidth
              value={search}
              placeholder="Поиск"
              onChange={(e) => setSearch(e.target.value)}
            />
          )}
        </Grid>
        {isDesktop && (
          <Grid item md={2}>
            {isSorting ? (
              <Button
                fullWidth
                variant="outlined"
                size="medium"
                onClick={() => setIsSorting(false)}
              >
                Отменить
              </Button>
            ) : (
              <Button
                fullWidth
                startIcon={<Icon icon="mdi:sort-numeric-descending" />}
                variant="outlined"
                size="medium"
                onClick={startSortingHandler}
              >
                Сортировать
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      {currencyListComponent}
    </Box>
  );
};

{
  /* <*/
}

export default Currencies;
