import { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";

import PageWrap from "src/components/PageWrap";

import { Icon } from "@iconify/react";

// Components
import StoreCryptocurrencyNets from "src/store/currency/CryptocurrencyNets";
import CurrencyNetSelect from "src/components/Currency/CurrencyNetSelect";
import WalletService from "src/service/Wallet";

import { observer } from "mobx-react-lite";

import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";

// Modules
import AddressItem from "./AddressItem";

function GeneralCrypto() {
  const [showAdd, setShowAdd] = useState(false);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    WalletService.generalAddressGetAll().then((r) => setAddresses(r.data));
  }, []);

  const nets = StoreCryptocurrencyNets.getNets;

  const addItem = (item) => {
    setAddresses((prev) => [item, ...prev]);
  };

  const deleteItem = (id) => {
    setAddresses(prev => prev.filter(a => a._id !== id))
  }

  return (
    <PageWrap title="Общие адреса">
      <AddDialog
        open={showAdd}
        nets={nets}
        handleClose={() => {
          setShowAdd(false);
        }}
        addItemHandler={addItem}
      />
      <Button
        startIcon={<Icon icon="material-symbols:add" />}
        variant="contained"
        sx={{ height: "100%" }}
        size="large"
        onClick={() => {
          setShowAdd(true);
        }}
      >
        Добавить
      </Button>

      <Box sx={{ mt: 4 }}>
        {addresses.map((a) => {
          return (
            <AddressItem
              key={a._id}
              data={a}
              style={{ mb: 2 }}
              deleteItem={deleteItem}
            />
          );
        })}
      </Box>
    </PageWrap>
  );
}
function AddDialog(props) {
  let { open, handleClose, nets = [], addItemHandler } = props;

  let netInitId = nets[0]?._id;
  // State
  const [isSubmiting, setSubmiting] = useState(false);
  const [netId, setNetId] = useState(netInitId);

  useEffect(() => {
    if (!netId && netInitId) {
      setNetId(netInitId);
    }
  }, [netInitId]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    let queryObj = {
      name: d.name,
      net: netId,
    };
    setSubmiting(true);
    WalletService.generalAddressCreate(queryObj)
      .then(({ data: newKit }) => {
        addItemHandler(newKit);
        toast.success("Адрес общей сети создан!");
        handleClose();
      })
      .finally(() => setSubmiting(false));
  });

  return (
    <Box>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            px: { xs: 8, sm: 12 },
            py: { xs: 8, sm: 12 },
            position: "relative",
          }}
        >
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
          >
            <Icon icon="mdi:close" />
          </IconButton>
          <Box sx={{ mb: 8, textAlign: "center" }}>
            <Typography variant="h6" sx={{ mb: 3, lineHeight: "2rem" }}>
              Добавить адрес сети
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name={"name"}
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  fullWidth
                  label="Название адреса"
                  placeholder="Введите название адреса"
                  id="name"
                  type="text"
                  error={Boolean(errors.name?.message)}
                  {...register("name", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.name?.message}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            <CurrencyNetSelect
              containerStyle={{ mt: 4 }}
              label="Сеть комплекта"
              placeholder="Выберите сеть"
              nets={nets}
              value={netId}
              setValue={setNetId}
            />
          </form>
        </DialogContent>
        <DialogActions sx={{ pb: { xs: 4, sm: 6 }, justifyContent: "center" }}>
          <Button
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
            variant="contained"
            sx={{ mr: 2 }}
            type="submit"
            onClick={onSubmit}
          >
            Добавить
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default observer(GeneralCrypto);
