import { useEffect, useState } from "react";
import { Box, Typography, Divider, Collapse, IconButton } from "@mui/material";

import { Icon } from "@iconify/react";

import OrderService from "src/service/Order";

export default function CurrencyBalance({ orderId }) {
  let [data, setData] = useState(null);
  let [show, setShow] = useState(false);

  useEffect(() => {
    OrderService.getWalletSnapshot(orderId).then((r) => {
      setData(r.data);
    });
  }, []);

  if (!data) return null;

  let {
    receiveCur,
    receiveCurBalance,
    receiveKitBalance,
    receiveKitName,
    receiveRequisiteBalance,
    receiveRequisiteName,
  } = data;

  let hasKitInfo = receiveKitBalance && receiveKitName;
  let hasRequisiteInfo = receiveRequisiteBalance && receiveRequisiteName;

  return (
    <>
      <Divider sx={{ my: "0px !important" }} />
      <Box
        sx={{
          p: "12px 12px !important",
        }}
      >
        <Box>
            {/* <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ mr: 1 }} variant="body2">
                Баланс <b>{receiveCur.name}</b>:
              </Typography>
              <Typography variant="body1">
                {receiveCurBalance} {receiveCur.code}
              </Typography>
            </Box> */}
            {hasKitInfo && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ mr: 1 }} variant="body2">
                  Комплект <b>{receiveKitName}</b>:
                </Typography>
                <Typography variant="body1">
                  {receiveKitBalance} {receiveCur.code}
                </Typography>
              </Box>
            )}
            {/* {hasRequisiteInfo && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ mr: 1 }} variant="body2">
                  Реквизит <b>{receiveRequisiteName}</b>:
                </Typography>
                <Typography variant="body1">
                  {receiveRequisiteBalance} {receiveCur.code}
                </Typography>
              </Box>
            )} */}
          </Box>
        {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
          <Typography sx={{ mr: 1 }} variant="body1">
            Балансы после получения
          </Typography>
          <IconButton sx={{p: 1.5}} onClick={() => setShow(!show)}>
            <Icon icon="material-symbols:expand-more-rounded" />
          </IconButton>
        </Box>
        <Collapse in={show} timeout="auto" unmountOnExit>
          
        </Collapse> */}
      </Box>
    </>
  );
}

// ex
