import { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Fab from "@mui/material/Fab";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField } from "@mui/material";

// Services
import SystemSettingsService from "src/service/SystemSettings";

// ** Icon
import Icon from "src/@core/components/icon";

//Toast
import { toast } from "react-hot-toast";

function MainSetting() {
  // State
  let [settings, setSettings] = useState(null);
  let [minProfitPercentError, setMinProfitPercentError] = useState("");

  useEffect(() => {
    SystemSettingsService.get().then((r) => setSettings(r.data));
  }, []);

  // Handlers
  const saveHandler = () => {
    if (settings.minProfitPercent < 0 || settings.minProfitPercent > 100) {
      return setMinProfitPercentError("Мин. процент 0, Макс процент 100");
    }
    SystemSettingsService.change({
      _id: settings._id,
      collectDebt: settings.collectDebt,
      minProfitPercent: settings.minProfitPercent
    }).then(() => toast.success("Настройки измененны!"));
  };

  if (!settings) return null;

  const changePercentHandler = (value) => {
    setMinProfitPercentError("");
    setSettings(Object.assign({}, settings, { minProfitPercent: value }));
  };

  return (
    <Card>
      <CardHeader
        title="Общие"
        {...{
          action: (
            <Fab
              color="primary"
              variant="extended"
              size="medium"
              sx={{ "& svg": { mr: 1 } }}
              onClick={saveHandler}
            >
              <Icon icon="material-symbols:save-outline-sharp" />
              Сохранить
            </Fab>
          ),
        }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <FormControlLabel
          label="Взымать задолженность"
          control={
            <Checkbox
              checked={settings.collectDebt}
              onChange={(e) =>
                setSettings(
                  Object.assign({}, settings, { collectDebt: e.target.checked })
                )
              }
              name="Взымать задолженность"
            />
          }
        /> */}
        <TextField
          placeholder="1%"
          value={settings.minProfitPercent}
          onChange={(e) => changePercentHandler(e.target.value)}
          error={Boolean(minProfitPercentError)}
          helperText={minProfitPercentError}
          size="small"
          type="number"
          fullWidth
          sx={{ mt: 2 }}
          label="Мин. процент прибыли"
        />
      </CardContent>
    </Card>
  );
}

export default MainSetting;
