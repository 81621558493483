import { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  CardContent,
  Box,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  TextField,
  ListItemAvatar,
  ListItemAvatarText,
  ListItemText,
  Avatar,
  List,
  ListItem,
} from "@mui/material";
// Congi
import ConfigApi from "src/configs/api";

// Form
import { useForm, Controller } from "react-hook-form";

import CurrencyService from "src/service/Currencies";

// Store
import CryptoCurrencyNetStore from "src/store/currency/CryptocurrencyNets";

import ImageUploader from "src/components/ImageUploader/ImageUploader";

import { observer } from "mobx-react-lite";
// ** Icon Imports
import Icon from "src/@core/components/icon";
import toast from "react-hot-toast";

function CryptoCurrencyNets() {
  const [showDialig, setShowDialog] = useState(false);

  const nets = CryptoCurrencyNetStore.nets;

  return (
    <Card>
      <DialogAddCurrency
        open={showDialig}
        handleClose={() => {
          setShowDialog(false);
        }}
      />
      <CardHeader
        action={
          <Box>
            <Button
              startIcon={<Icon icon="mdi:plus" />}
              type="submit"
              onClick={() => setShowDialog(true)}
              variant="contained"
            >
              Добавить
            </Button>
          </Box>
        }
        title={"Сети Криптовалют"}
        subheader="Требуются для верной работы функций криптовалют (Общие комплекты)"
      />
      <CardContent>
        <List
          dense
          sx={{
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          {nets.map((n, i) => (
            <ListItem sx={{px: 0}} key={n._id}>
              <ListItemAvatar>
                <Avatar
                  src={ConfigApi.PUBLIC_IMAGES + n.logo + ".png"}
                  alt={n.name}
                  sx={{ height: 36, width: 36 }}
                />
              </ListItemAvatar>
              <ListItemText primary={n.name} secondary={n.code} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

function DialogAddCurrency(props) {
  let { open, handleClose, addItemHandler } = props;
  let [isSubmiting, setSubmiting] = useState(false);
  let [imageName, setImageName] = useState("");

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    let d = {
      name: data.name,
      code: data.code,
      logo: imageName,
    };

    setSubmiting(true);
    CurrencyService.cryptoCurrencyCreate(d)
      .then(({ data }) => {
        handlerClose();
        CryptoCurrencyNetStore.addItem(data);
        toast.success("Сеть добавлена!");
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  const uploadedImageHandler = (name) => {
    setImageName(name);
    toast.success("Изображение загружено!");
  };

  const handlerClose = () => {
    setImageName("");
    setValue("name", "");
    setValue("code", "");
    setSubmiting(false);
    handleClose();
  };

  return (
    <Box>
      <Dialog maxWidth="xs" open={open} onClose={handlerClose}>
        <DialogTitle>Добавить валюту</DialogTitle>
        <DialogContent
          sx={{
            px: { xs: 8, sm: 15 },
            pt: { xs: 8, sm: 12.5 },
            position: "relative",
            pb: 6,
          }}
        >
          <ImageUploader
            uploadSuccesHandler={uploadedImageHandler}
            sx={{ mb: 4 }}
            purpose="icon"
          />
          <form noValidate onSubmit={onSubmit}>
            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Имя"
                  placeholder="Название"
                  autoFocus
                  id="name"
                  type="text"
                  error={Boolean(errors.name?.message)}
                  {...register("name", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.name?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Controller
              name={"code"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Код"
                  placeholder="Код"
                  autoFocus
                  id="code"
                  type="text"
                  error={Boolean(errors.code?.message)}
                  {...register("code", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.code?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
        >
          <Button
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting || !imageName}
            variant="contained"
            sx={{ mr: 2 }}
            onClick={onSubmit}
          >
            Добавить
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default observer(CryptoCurrencyNets);
