import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  Autocomplete,
  TextField,
  Box,
  Avatar,
  Button,
  CircularProgress,
  CardContent,
} from "@mui/material";
import PageWrap from "src/components/PageWrap";

// LIBS
import InfiniteScroll from "react-infinite-scroll-component";

import MultipleSelectSite from "src/components/Site/MultipleSelectSite";

import ExchangeService from "src/service/Exchange";

import GeneralCourseCard from "./GeneralCourseCard";

// Config
import ConfigApi from "src/configs/api";

const LIMIT = 10;

const GeneralCoursesPage = () => {
  const [filter, setFilter] = useState({});
  const [sites, setSites] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [takeCurrencies, setTakeCurrencies] = useState([]);
  const [giveCurrencies, setGiveCurrencies] = useState([]);
  const [exchanges, setExchanges] = useState([]);

  useEffect(() => {
    ExchangeService.getExchangeCoursesFilter().then(({ data }) => {
      setFilter(data);
    });
  }, []);

  let { takeListCurrency, giveListCurrency } = getFiltredFilterData(filter);

  const findHandler = (isNew = false) => {
    console.log("find handler");
    setIsSubmit(true);
    ExchangeService.findGeneralExchange({
      sites,
      takes: takeCurrencies.map((c) => c._id),
      gives: giveCurrencies.map((c) => c._id),
      pagination: {
        limit: LIMIT,
        skip: isNew ? 0 : exchanges.length,
      },
    })
      .then(({ data }) => {
        if (isNew) {
          setExchanges(data);
        } else {
          setExchanges((prev) => [...prev, ...data]);
        }
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return (
    <PageWrap title={`Курсы (Общее)`}>
      <Card
        sx={{
          p: "12px 16px",
        }}
      >
        <Grid container spacing={4} justifyContent="flex-end">
          <Grid item xs={12} md={4}>
            <MultipleSelectSite
              isAddAll
              siteList={sites}
              setSiteList={setSites}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CurrencyAutocomplete
              currencies={takeListCurrency}
              selectedCurrencies={takeCurrencies}
              setSelectedCurrencies={setTakeCurrencies}
              label="Отдаю"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CurrencyAutocomplete
              currencies={giveListCurrency}
              selectedCurrencies={giveCurrencies}
              setSelectedCurrencies={setGiveCurrencies}
              label="Получаю"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              disabled={isSubmit}
              startIcon={isSubmit ? <CircularProgress size="22px" /> : null}
              onClick={() => {
                findHandler(true);
              }}
              fullWidth
              variant="contained"
            >
              Поиск
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Box sx={{ mt: 5 }}>
        <InfiniteScroll
          dataLength={exchanges.length}
          next={findHandler}
          hasMore={true}
        >
          {exchanges.map((e) => (
            <GeneralCourseCard style={{ mb: 3 }} key={e._id} data={e} />
          ))}
        </InfiniteScroll>
        {isSubmit && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!exchanges.length && (
          <Card>
            <CardContent
              sx={{
                p: "12px 18px !important",
                textAlign: "center",
              }}
            >
              Список пуст
            </CardContent>
          </Card>
        )}
      </Box>
    </PageWrap>
  );
};

function CurrencyAutocomplete({
  currencies = [],
  selectedCurrencies = [],
  setSelectedCurrencies,
  label = "",
}) {
  // _id code image name
  return (
    <Autocomplete
      disablePortal
      limitTags={1}
      // id="combo-box-demo"
      value={selectedCurrencies}
      options={currencies}
      autoHighlight
      fullWidth
      onChange={(event, newValue) => {
        setSelectedCurrencies(newValue);
      }}
      multiple
      getOptionLabel={(option) => `${option.name} (${option.code})`}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const { _id, name, image, code } = option;
        return (
          <Box key={_id} component="li" {...optionProps}>
            <Avatar
              variant="square"
              src={ConfigApi.PUBLIC_IMAGES + image + ".png"}
              alt={name}
              sx={{ height: 30, width: 30, mr: 3 }}
            />
            {name} ({code})
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="Выбор валюты" />
      )}
    />
  );
}

function getFiltredFilterData(filter = {}) {
  let pairs = [];
  Object.values(filter).forEach((arr) => {
    pairs.push(...arr);
  });
  let takeListCurrency = filtredCurrenciesUnique([...pairs]).map(
    transformedCurrency
  );

  let giveListCurrency = [];
  pairs.forEach((p) => {
    giveListCurrency.push(...p.pairs);
  });
  giveListCurrency =
    filtredCurrenciesUnique(giveListCurrency).map(transformedCurrency);

  return {
    takeListCurrency,
    giveListCurrency,
  };
}

const transformedCurrency = (c) => {
  let cur = { ...c };
  delete cur.pairs;
  return cur;
};

const filtredCurrenciesUnique = (currencies) => {
  let uniqueCurrenices = {};
  currencies = [...currencies];
  let result = currencies.filter((item) => {
    return uniqueCurrenices.hasOwnProperty(item._id)
      ? false
      : (uniqueCurrenices[item._id] = true);
  });
  return result;
};

export default GeneralCoursesPage;
