export default function sortCurrencies(list) {
  list.sort((a, b) => {
    // Сначала сравниваем по балансу по убыванию
    let abalance = a?.wallet?.balance || 0;
    let bbalance = b?.wallet?.balance || 0;

    if (abalance > bbalance) {
      return -1;
    } else if (abalance < bbalance) {
      return 1;
    }

    let aname = a.name.toLowerCase();
    let bname = b.name.toLowerCase();

    // Если балансы равны, сравниваем по имени по возрастанию (алфавиту)
    if (aname < bname) {
      return -1;
    } else if (aname > bname) {
      return 1;
    } else {
      return 0;
    }
  });

  // list.sort((a, b) => {

  //   if (abalance < bbalance) {
  //     return 1;
  //   } else if (abalance > bbalance) {
  //     return -1;
  //   } else {
  //     return 0;
  //   }
  // });
  // list.sort((a, b) => {
  //   if (a.isCrypto && b.isCrypto) {
  //     return a.code.localeCompare(b.code);
  //   } else if (a.isCrypto) {
  //     return 1;
  //   } else if (b.isCrypto) {
  //     return -1;
  //   } else {
  //     return a.code.localeCompare(b.code);
  //   }
  // });

  return list;
}
