import { useState, useEffect } from "react";
//MUi
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Custom ui
import ActiveSwitch from "./Ui/ActiveSwitch";
import CustomTextField from "./Ui/CustomTextField";
import Favorite from "./Ui/Favorite";

// Custom Components
import ViewImage from "src/components/ViewImage";

// Modules
import ConfirmChangeCryptoRequisite from "./CryptoRequisiteModules/ConfirmChangeCryptoRequisite";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import { Typography } from "@mui/material";

// Toast
import toast from "react-hot-toast";

// Socket
import { socket } from "src/socket";

import { observer } from "mobx-react-lite";
import CryptoCurrencyNetStore from "src/store/currency/CryptocurrencyNets";
// Services
import WalletService from "src/service/Wallet";

// Config
import ConfigApi from "src/configs/api";

export default function CryptoRequisite(props) {
  let { data, deleteHandler, style } = props;

  // States
  let [showQr, setShowQr] = useState(false);
  let [requisite, setRequisite] = useState(data);
  let [fields, setFields] = useState(data.fields);

  // Calc
  let hasFields = requisite.fields.length > 0;
  let address = requisite.fields.find((f) => f.validation == "address")?.value;
  let { changed: isChanged, fields: changeFields } = getChangedFields(
    getFieldsObj(fields),
    getFieldsObj(requisite.fields)
  );

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.only("md"));

  // Handlers
  const changeItemField = (id, value) => {
    setFields((fields) =>
      fields.map((f) => (f._id !== id ? f : Object.assign({}, f, { value })))
    );
  };

  const updateReqHandler = (upd) => {
    setRequisite((r) => Object.assign({}, r, upd));
    if (upd.fields) {
      setFields(upd.fields);
    }
  };

  const saveHandler = () => {
    // fields

    let fieldList = requisite.fields.map((f) => {
      return Object.assign({}, f, { value: changeFields[f._id] || "" });
    });

    WalletService.changeRequisite({
      _id: requisite._id,
      fields: fieldList,
    }).then(({ data }) => {
      if (data?.status == "confirm-change") {
        toast.success(`Ожидает подтверждения!`);
      } else {
        toast.success(`Реквизит изменен!`);
      }
      // status: ,
    });
  };

  // Hooks
  useEffect(() => {
    socket.on(`update_req_${data._id}`, updateReqHandler);

    return () => {
      socket.off(`update_req_${data._id}`);
    };
  }, []);

  let addedStyle = {};

  if (data.isNet) {
    addedStyle = {
      border: (t) => `1px solid ${t.palette.primary.light}`,
    };
  }

  return (
    <Card sx={{ ...style, position: "relative", ...addedStyle }}>
      {data.isNet && (
        <NetBadge
          netId={data.net}
          isLeft={isMediumScreen}
          style={
            isMediumScreen
              ? {
                  position: "absolute",
                  top: "60%",
                }
              : {
                  position: "absolute",
                  top: "10%",
                  right: "0%",
                }
          }
        />
      )}
      <ConfirmChangeCryptoRequisite requisiteId={requisite._id} />
      <ViewImage
        show={showQr}
        handleClose={() => setShowQr(false)}
        isQrCode
        value={address}
      />
      <CardContent>
        <Grid container spacing={4} columns={20} justifyContent="flex-end">
          <Grid item xs={20} md={1}>
            <ActiveSwitch number={data.number} hideToggle style={{ mr: 3 }} />
          </Grid>
          <Grid item xs={20} md={17}>
            {hasFields ? (
              <Grid container spacing={4}>
                {fields.map((f, i) => {
                  return (
                    <Grid key={f._id} item xs={12} md={6}>
                      <Box sx={{ display: "flex" }}>
                        <CustomTextField
                          label={f.name}
                          value={f.value}
                          disabled={data.isNet}
                          onChange={(e) =>
                            changeItemField(f._id, e.target.value)
                          }
                        />
                        {i == 0 && (
                          <Favorite
                            _id={requisite._id}
                            isFavorite={requisite.isFavorite}
                            style={{ ml: 2 }}
                          />
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography variant="body1" textAlign="center">
                Нет полей
              </Typography>
            )}
          </Grid>

          <Grid item xs={20} md={2}>
            <Box
              sx={{
                minHeight: "100%",
                display: "flex",
                flexDirection: {
                  xs: "row-reverse",
                  md: "column",
                },

                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {isChanged && (
                  <Button
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      minWidth: 38,
                      mb: {
                        xs: 0,
                        md: 2,
                      },
                      alignSelf: "flex-start",
                    }}
                    color="primary"
                    onClick={saveHandler}
                  >
                    <Icon icon="material-symbols:save-outline-rounded" />
                  </Button>
                )}

                {address && (
                  <Button
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      minWidth: 38,
                      mb: {
                        xs: 0,
                        md: 3,
                      },
                      ml: 3,
                    }}
                    color={"secondary"}
                    onClick={() => setShowQr(true)}
                  >
                    <Icon icon="material-symbols:qr-code-2-rounded" />
                  </Button>
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  sx={{
                    p: 1.5,
                    minWidth: 38,
                    ml: {
                      xs: 0,
                      md: 3,
                    },
                  }}
                  color={"error"}
                  onClick={() => deleteHandler(data._id)}
                >
                  <Icon icon="ic:round-delete-forever" />
                </Button>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={20} md={2}>
              212
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
}

function getFieldsObj(fields) {
  let result = {};
  fields.forEach((f) => {
    result[f._id] = f.value || "";
  });
  return result;
}

const NetBadge = observer(function ({ netId, style = {}, isLeft = true }) {
  const nets = CryptoCurrencyNetStore.getNets;
  const net = nets.find((n) => n._id == netId);

  if (!net) return null;

  let appendRadius = isLeft
    ? {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
      }
    : {
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
      };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: (t) => t.palette.primary.light,
        p: "6px 12px !important",
        ...appendRadius,
        ...style,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Avatar
          variant="square"
          src={ConfigApi.PUBLIC_IMAGES + net.logo + ".png"}
          alt={net.name}
          sx={{ height: 23, width: 23, mr: 3 }}
        />
        {net.name}
      </Box>
    </Box>
  );
});
function getChangedFields(newObj, savedObj) {
  const result = { changed: false, fields: {} };

  // Сравниваем свойства объектов

  for (let prop in newObj) {
    if (newObj[prop] !== savedObj[prop]) {
      result.changed = true;
    }
    result.fields[prop] = newObj[prop];
  }

  return result;
}
