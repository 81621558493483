import WEBSITES from "src/configs/websites";
import WEBSITESANDSERVICES from "src/configs/websitesAndServices";


const contentPages = [
  {
    title: "Соглашение",
    path: "/content/agreement",
  },
  {
    title: "Уведомление",
    path: "/content/notification",
  },
  {
    title: "Контакты",
    path: "/content/contacts",
  },
  {
    title: "Реклама",
    path: "/content/ad",
  },
  {
    title: "FAQ",
    path: "/content/faq",
  },
  {
    title: "Новости",
    path: "/content/news",
  },

  {
    title: "Рассылка",
    path: "/content/mail",
  },
];

const navigation = () => {
  const contentChildren = [];

  WEBSITES.forEach((s) => {
    contentChildren.push({
      title: s.name,
      children: contentPages.map((page) => {
        return {
          title: page.title,
          path: page.path + "/" + s.code,
        };
      }),
    });
  });

  const currenciesChildren = [];

  WEBSITESANDSERVICES.forEach((s) => {
    currenciesChildren.push({
      title: s.name,
      path: "/currencies/" + s.id,
    });
  });

  return [
    {
      title: "Заявки",
      path: "/orders",
      icon: "ic:twotone-currency-exchange",
    },
    {
      title: "Панель",
      path: "/dashboard",
      icon: "material-symbols:dashboard",
    },

    {
      title: "Валюты",
      icon: "ri:coins-line",
      children: currenciesChildren,
    },
    {
      title: "Вознаграждение",
      icon: "fluent:reward-12-filled",
      path: "/rewards",
    },
    {
      title: "Настройки",
      icon: "material-symbols:settings",
      children: [
        {
          title: "Комментарии к картам",
          path: "/settings/card-comments",
        },
        {
          title: "Валюта",
          path: "/settings/currency",
        },
      ],
    },
    {
      title: "Контент",
      icon: "material-symbols:content-paste-rounded",
      children: contentChildren,
    },
    {
      title: "Реквизиты",
      icon: "uil:wallet",
      children: [
        {
          title: "Управление",
          path: "/requisites/control",
        },
        {
          title: "Общие",
          path: "/requisites/global",
        },
        {
          title: "История",
          children: [
            {
              title: "Обмены",
              path: "/requisites/history/exchanges",
            },
            {
              title: "Внутренние переводы",
              path: "/requisites/history/transfer",
            },
          ],
        },
      ],
    },
    {
      title: "Пользователи",
      icon: "mdi:user",
      path: "/clients",
    },
  ];
};

export default navigation;
