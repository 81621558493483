import { Fragment } from "react";

// Mui
import Divider from "@mui/material/Divider";

// Moduels
import OtherBase from "./OtherBase";
import Fields from "./Fields";
import Notifications from "./Notifications";

;



function OtherSettings(props) {
  let { currency } = props;

  const isPaymentLink = currency?.settings?.paymentLink;

  return (
    <Fragment>
      <OtherBase isPaymentLink={isPaymentLink} info={currency.info} isCrypto={currency.isCrypto}  />
      <Divider sx={{ my: "0px !important" }} />
      <Fields info={currency.info} />
      <Divider sx={{ my: "0px !important" }} />
      <Notifications info={currency.info} style={{ mt: 0 }} />
    </Fragment>
  );
}

export default OtherSettings;
