import axios from "axios";

class Currencies {

  getCityCodes() {
    return axios.get("/currencies/city-codes");
  }
  getAvailableList() {
    return axios.get("/currencies/all-available");
  }
  addCurrency(data) {
    return axios.post("/currencies", data);
  }
  changeCurrency(data) {
    return axios.put("/currencies", data);
  }
  getAllCurrencies() {
    return axios.get("/currencies/all");
  }
  getList(query) {
    return axios.get("/currencies", { params: query });
  }
  getOneFullInfo(id) {
    return axios.get(`/currencies/${id}`);
  }
  // Settings
  settingsGetById(id) {
    return axios.get(`/currencies/settings/${id}`);
  }
  settingsChange(upd) {
    return axios.put(`/currencies/settings`, upd);
  }
  // Info
  infoChange(upd) {
    return axios.put(`/currencies/info`, upd);
  }

  // Nets
  netsChange(upd) {
    return axios.put(`/currencies/nets`, upd);
  }
  netsUnified(upd) {
    return axios.put(`/currencies/nets/unified`, upd);
  }
  // Cryptocurrency Nets
  cryptoCurrencyNetsGetAll() {
    return axios.get(`/currencies-crypto-nets`);
  }
  cryptoCurrencyCreate(data) {
    return axios.post(`/currencies-crypto-nets`, data);
  }


  // .post(
  //   "",
  //   adminMiddleware,
  //   currenciesController.cryptoCurrencyCreate
  // )
  // .get(
  //   "/currencies/crypto-nets",
  //   adminMiddleware,
  //   currenciesController.cryptoCurrencyGetAll
  // );

  // unified
}

export default new Currencies();
